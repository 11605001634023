import { DocumentMaster } from "projects/userapp/src/app/module/login/model/DocumentMaster.model";



export class PredefinedMaster{
    public predefinedId:number
    public name:string;
    public subName:string;
    public entityType:string;
    public type:string;
    public typeData:any;
    public field1:string;
    public field2:string;
    public field3:string;
    public field4:string;
    public field5:string;
    public field6:string;
    public code:string;
    public fileName:string;
    public filePath:string;
    public fileKey:string;
    public nameAndSubname:string;
    public displayOrder:number;
    public sortOrder:string;
    public entitySubType:string;
    public pageNumber: number;
    public pageSize: number;
    public rowId:string;
    public file:File;
    public documentMaster:DocumentMaster;
    public isDelete:string;
    public documentSelectedPath: string;
    public folderName:string;
    public fieldName:string;
    public actualPath:string;
    public documentName:string;
   
   
}
