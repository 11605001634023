import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModuleMaster } from '../model/modulemaster.model';
import { ModuleMasterService } from '../service/modulemaster.service';
import { AuthService } from '../../service/auth.service';
import { ConfirmationService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-modulemaster',
  templateUrl: './modulemaster.component.html',
  styleUrls: ['./modulemaster.component.css']
})
export class ModulemasterComponent implements OnInit {

  @ViewChild('moduleModalClose') moduleModalClose:ElementRef;
  moduleMaster:ModuleMaster=new ModuleMaster();
  moduleMasterFilter:ModuleMaster=new ModuleMaster();
  moduleMasterList:ModuleMaster[]=[];
  moduleMasterSelectedList:ModuleMaster[]=[];
  pageSize:number=10;
  pageNumber:number=0;
  totalRecords:number=0;
  cols:any[]=[]; 

  constructor(private moduleMasterService: ModuleMasterService,private authService:AuthService,
    private confirmationService:ConfirmationService,
    private spinnerService:NgxSpinnerService) { }

  ngOnInit() {

    this.cols=[

      {filterfield:'moduleName',field:'moduleName',header:'Module Name',width : '200px'},
      {filterfield:'moduleCode',field:'moduleCode',header:'Module Code',width : '120px'},
      {filterfield:'moduleUrl',field:'moduleUrl',header:'Module URL',width : '200px'}
    ];
    this.moduleMasterFilter.pageNumber=this.pageNumber;
    this.moduleMasterFilter.pageSize=this.pageSize;
    this.getModuleList();
  }

  addModuleModal(){
    this.moduleMaster=new ModuleMaster();
    this.moduleMaster.isDelete="N";
  }

  updateModuleModel(moduleMaster:ModuleMaster){
    this.moduleMaster=moduleMaster;
  }

  saveModule(){
    this.moduleMasterService.saveModule(this.moduleMaster).subscribe( data => {

      if(this.moduleMaster.moduleId===null || this.moduleMaster.moduleId===undefined || this.moduleMaster.moduleId===0){
        this.getModuleList();
        this.moduleModalClose.nativeElement.click();
        this.authService.showMessage('success','Module Saved Successfully','');
      }else{
        this.getModuleList();
        this.moduleModalClose.nativeElement.click();
        this.authService.showMessage('success','Module Updated Successfully','');
      }

      
    },error =>{
      if (error instanceof HttpErrorResponse) {
                 
        if (error.status === 200) {
           // console.log(error.error.text);
           //json circular break at server side always add 
           //extra information of timestamp and status along with 
           //main json so parsing occurs still at status 200
           let jsonString=error.error.text;
           jsonString=jsonString.substr(0, jsonString.indexOf('{"timestamp"')); 
           this.moduleMasterList=JSON.parse(jsonString);
        }else{
         // this.authService.showMessage('error','Something Went Wrong','');
      }
      
      }
    });
  }

  deleteModule(moduleMaster:ModuleMaster):void{
    console.log("delete employee");
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        moduleMaster.isDelete='Y';
        this.moduleMasterService.deleteModule(moduleMaster).subscribe( data => {
          // alert("salary component deleted successfully.");
            this.authService.showMessage('success','Deleted Successfully','');
            this.getModuleList();
        },error =>{
          if (error instanceof HttpErrorResponse) {
                    
            if (error.status === 500) {
              // console.log(error.error.text);
              //json circular break at server side always add 
              //extra information of timestamp and status along with 
              //main json so parsing occurs still at status 200
            //  alert("salary component cannot be deleted.");
              this.authService.showMessage('error','Module cannot be deleted','');
            }else{
              this.authService.showMessage('error','Something Went Wrong','');
          }
          }
        });
      },
      reject: () => {
        
      }
  });







    
  }


getModuleList(){
  this.getModuleListLength();
  this.moduleMasterService.getModuleList(this.moduleMasterFilter).subscribe( data => {
   this.moduleMasterList=data;
  },error =>{
    if (error instanceof HttpErrorResponse) {
               
      if (error.status === 200) {
         // console.log(error.error.text);
         //json circular break at server side always add 
         //extra information of timestamp and status along with 
         //main json so parsing occurs still at status 200
         let jsonString=error.error.text;
         jsonString=jsonString.substr(0, jsonString.indexOf('{"timestamp"')); 
         this.moduleMasterList=JSON.parse(jsonString);
      }else{
       // this.authService.showMessage('error','Something Went Wrong','');
    }
    
    }
  });
}


getModuleListLength(){
  this.moduleMasterService.getModuleListLength(this.moduleMasterFilter).subscribe( data => {
   this.totalRecords=data;
  },error =>{
    if (error instanceof HttpErrorResponse) {
               
      if (error.status === 200) {
         // console.log(error.error.text);
         //json circular break at server side always add 
         //extra information of timestamp and status along with 
         //main json so parsing occurs still at status 200
         let jsonString=error.error.text;
         jsonString=jsonString.substr(0, jsonString.indexOf('{"timestamp"')); 
         this.totalRecords=JSON.parse(jsonString);
      }else{
       // this.authService.showMessage('error','Something Went Wrong','');
    }
    
    }
  });
}


paginate(event) {

  this.moduleMaster.pageNumber=event.page;
  this.moduleMaster.pageSize=this.pageSize;
  this.getModuleList();
  
}

}
