

export class ModuleMaster{
    public moduleId:number
    public moduleName:string;
    public moduleCode:string;
    public moduleUrl:string;
    public isDelete:string;

    public pageNumber: number;
    public pageSize: number;
}
