import { ModuleMaster } from "../../../system/model/modulemaster.model";


export class FilterModuleMaster{
    public filterModuleId:number
    public moduleMaster:ModuleMaster;
    public fieldName:string;
    public fieldType:string;
    public filterType:string;
    public width:string;
    public lookupValues:string;
    public lookupSelect:string;
    public singleSelect:string;
    public lookupLabelValue:string;
    public defaultLabel:string;
    public lookupQuery:string;
    public lookupCriteria1:string;
    public lookupCriteria2:string;
    public lookupCriteria3:string;
    public filterLookupList:any[];
    public showDefaultFilter:boolean;

    public searchValue1:string;
    public searchValue2:string;
    public searchValue3:string;




}