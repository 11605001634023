import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { AccessControlMaster } from '../../system/model/accesscontrolmaster.model';
import { AuthService } from '../../service/auth.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { PredefinedMaster } from 'src/app/shared/predefinedsettings/model/predefinedmaster.model';
import { PredefinedService } from 'src/app/shared/predefinedsettings/service/predefined.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Output() deleteDataEvent: EventEmitter<any> = new EventEmitter();
  @Output() editEntityEvent:EventEmitter<any> = new EventEmitter();
  @Output() downloadEntityEvent:EventEmitter<any> = new EventEmitter();
  @Output() addFeaturesEvent:EventEmitter<any> = new EventEmitter();
  @Output() viewTrackEvent:EventEmitter<any> = new EventEmitter();
  @Output() assignTaskEvent:EventEmitter<any> = new EventEmitter();
  @Output() viewEntityEvent:EventEmitter<any> = new EventEmitter();
  @Output() viewOrderEvent:EventEmitter<any> = new EventEmitter();
  @Output() viewFoodEvent:EventEmitter<any> = new EventEmitter();
  @Output() viewStatusEvent:EventEmitter<any> = new EventEmitter();
  @Output() editModalEvent:EventEmitter<any> = new EventEmitter();
  @Output() individualInfoEvent:EventEmitter<any> = new EventEmitter();
  @Output() reportModalEvent:EventEmitter<any> = new EventEmitter();
  @Output() mapEntityEvent:EventEmitter<any> = new EventEmitter();
  @Output() rowSelectEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowUnSelectEvent: EventEmitter<any> = new EventEmitter();
  @Output() addLeadEvent:EventEmitter<any> = new EventEmitter();
  @Output() paginateEvent:EventEmitter<any> = new EventEmitter();
  @Output() selectDataEvent:EventEmitter<any> = new EventEmitter();
  @Output() leadHistoryEvent:EventEmitter<any> = new EventEmitter();
  @Output() myListEvent:EventEmitter<any> = new EventEmitter();
  @Output() onVideoSelectModalEvent:EventEmitter<any> = new EventEmitter();
  @Output() activeDeactiveEvent:EventEmitter<any> = new EventEmitter();
  @Output() showHidePostEvent:EventEmitter<any> = new EventEmitter();
  @Output() myListToLeadEvent:EventEmitter<any> = new EventEmitter();
  @Output() loadLazyEvent:EventEmitter<any> = new EventEmitter();
  @Output() filterCall:EventEmitter<any> = new EventEmitter();
  @Output() filterEvent:EventEmitter<any> = new EventEmitter();
  @Output() operationEvent:EventEmitter<any> = new EventEmitter();
  @Output() toggleRowEvent:EventEmitter<any> = new EventEmitter();
  @Output() postAbuseViewEvent:EventEmitter<any> = new EventEmitter();
  @Output() approveEvent:EventEmitter<any> = new EventEmitter();
  //@Output() moveEvent:EventEmitter<any> = new EventEmitter();
  @Output() postPermissionEvent:EventEmitter<any> = new EventEmitter();
  @Output() viewSalesEvent:EventEmitter<any> = new EventEmitter();
  @Output() showAmountEvent:EventEmitter<any> = new EventEmitter();
  @Output() viewQuestionEvent:EventEmitter<any> = new EventEmitter();
  @Output() onSelectModalEvent:EventEmitter<any> = new EventEmitter();
  @Output() paymentClearanceEvent:EventEmitter<any> = new EventEmitter();
  @Output() clearanceStatusEvent:EventEmitter<any> = new EventEmitter();
  @Output() markAsDeliveredEvent:EventEmitter<any> = new EventEmitter();

  @Input() genericColsInput:any[]=[];
  @Input() genericListInput:any[]=[];
  @Input() genericTableSettingInput:any;
  @Input() genericSelectedListInput:any;
  @Input() genericSelectedListInputList:any[]=[];
  @Input() entityType:string="";
  @Input() totalRecords:number=0;
  @Input() totalNumber:number=0;
  @Input() filterMaster: any;


  dataTagList: PredefinedMaster[] = [];
  assappTagList: PredefinedMaster[] = [];
  masterTagListFilter: PredefinedMaster[] = [];
  groupMasterList: PredefinedMaster[] = [];
  subIndustryMasterList : PredefinedMaster[] = [];
  designationMasterList: PredefinedMaster[] = [];
  designationLevelMasterList : PredefinedMaster[] = [];
  functionList: PredefinedMaster[] = [];
  equityList : PredefinedMaster[] = [];
  locationList: PredefinedMaster[] = [];
  prefixList: PredefinedMaster[] = [];
  socialMediaList: PredefinedMaster[] = [];
  checked1: boolean = false;
  checked2: boolean = true;

  accessControlMasterMap: { [moduleCode: string]: AccessControlMaster; } = { };

  selectedColumns :any[];
  selectedRow : any;
  selectedImage : any;
  actionText:string;
  viewData:boolean;
  constructor(private confirmationService:ConfirmationService,private authService:AuthService , 
    private predefinedService: PredefinedService,public router:Router) { }

  ngOnInit() {
    // console.log("genericColsInput sortMode "+this.genericTableSettingInput.sortMode);
    // console.log("genericColsInput scrollHeight "+this.genericTableSettingInput.scrollHeight);
    // console.log("filterMaster"+this.filterMaster);

    this.accessControlMasterMap=this.authService.getAccessControlMapData();



    // this.getPredefinedListByType("DATA");
    // this.getPredefinedListByType("ASAPP");
    // this.getPredefinedListByType("MASTER");
    // this.getPredefinedListByType("INDUSTRY");
    // this.getPredefinedListByType("GROUP");
    // this.getPredefinedListByType("LOCATION");
    // this.getPredefinedListByType("DESIGNATIONLEVEL");
    // this.getPredefinedListByType("LOCATION");
    // this.getPredefinedListByType("DESIGNATION");
    // this.getPredefinedListByType("FUNCTION");
    // this.getPredefinedListByType("DESIGNATION");
    // this.getPredefinedListByType("PREFIX");
    // this.getPredefinedListByType("EQUITY");
    // this.getPredefinedListByType("SOCIALMEDIA");

   this.selectedColumns =  this.genericColsInput;
  //  if(this.router.url == '/panel/GSG/all-customers'){
  //   this.actionText = "Order history";
  //   this.viewData = true;

  //  }
  
  //  else{
  //    this.actionText ="Action";
  //    this.viewData = false;
  //  }
  }

  myList(rowData:any){
    this.myListEvent.emit(rowData);
  }
  individualInfoModal(rowData:any) {
    this.individualInfoEvent.emit(rowData);
  }
  filterData(){
    this.filterEvent.emit(this.filterMaster);
  }

  approve(rowData:any){
    this.approveEvent.emit(rowData);
  }

  deleteEntity(rowData:any){
    this.deleteDataEvent.emit(rowData);
  }

  showAmount(rowData:any){
    this.showAmountEvent.emit(rowData);
    /*
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
      
        //this.genericListInput = this.genericListInput.filter(data => data !== rowData);
      },
      reject: () => {

      }
  });*/
  }



  loadLazy(event:LazyLoadEvent){
    this.loadLazyEvent.emit(event);
  }


  activeDeactive(rowData:any,type:string){
    console.log("activeDeactive" +type);
    if(type==="active"){
      rowData.isDeactive="N";
    }else  if(type==="deactive"){
      rowData.isDeactive="Y";
    }


    /*
    console.log("activeDeactive" +type);
   
    let msg="";

    if(type==="active"){
      msg="Are you sure that you want to activate?";
    }else  if(type==="deactive"){
      msg="Are you sure that you want to deactivate?";
    }

    this.confirmationService.confirm({
      message: msg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        if(type==="active"){
          rowData.isDeactive="N";
        }else  if(type==="deactive"){
          rowData.isDeactive="Y";
        }

        this.activeDeactiveEvent.emit(rowData);
      },
      reject: () => {
        if(rowData.isDeactive=="N"){
          rowData.isDeactive="Y";
        }else  if(rowData.isDeactive=="Y"){
          rowData.isDeactive="N";
        }
      }
  });*/
    this.activeDeactiveEvent.emit(rowData);
  }


  showHidePost(rowData:any,type:string){
    if(type==="active"){
      rowData.showPost = true;
    }else  if(type==="deactive"){
      rowData.showPost = false;
    }

    this.showHidePostEvent.emit(rowData);
  }

  postPermission(rowData:any,type:string){
    if(type==="active"){
      rowData.postRequest = true;
    }else  if(type==="deactive"){
      rowData.postRequest = false;
    }

    this.postPermissionEvent.emit(rowData);
  }


  editEntity(rowData:any){
    console.log("editEntity");
    this.editEntityEvent.emit(rowData);
  }

  paymentClearanceEntity(rowData:any){
    console.log("paymentClearanceEntity");
    this.paymentClearanceEvent.emit(rowData);
  }

  markAsDelivered(rowData:any){
    this.markAsDeliveredEvent.emit(rowData);
  }


  changeClearanceStatus(rowData:any){
    this.clearanceStatusEvent.emit(rowData);
  }

  downloadEntity(rowData:any){
    this.downloadEntityEvent.emit(rowData);
  }

  addFeatures(rowData:any){
    this.addFeaturesEvent.emit(rowData);
  }

  viewTrack(rowData:any){
    this.viewTrackEvent.emit(rowData);
  }

  assignTask(rowData:any){
    this.assignTaskEvent.emit(rowData);
  }

  viewEntity(rowData:any){
    // console.log(rowData);
    this.viewEntityEvent.emit(rowData);
  }
  viewOrder(rowData:any){
    // console.log(rowData);
    this.viewOrderEvent.emit(rowData);
  }
  viewfoodMenu(rowData:any){
    // console.log(rowData);
    this.viewFoodEvent.emit(rowData);
  }
  StatusChanngeVendor(rowData:any){
    // console.log(rowData);
    this.viewStatusEvent.emit(rowData);
  }
  postAbuseView(rowData:any){
    this.postAbuseViewEvent.emit(rowData);
  }


  leadHistory(rowData:any){
    this.leadHistoryEvent.emit(rowData);
  }

  viewMap(rowData:any){
    // console.log("event view map ");
    this.mapEntityEvent.emit(rowData);
  }

  rowSelect(rowData:any){
    rowData.data.entityType=this.entityType;
    this.rowSelectEvent.emit(rowData.data);
    // console.log("rowSelect");
    this.selectDataEvent.emit(this.genericSelectedListInput);
  }

  onRowUnselect(rowData:any){
    rowData.data.entityType=this.entityType;
    this.rowUnSelectEvent.emit(rowData.data);

  }

  addLead(rowData:any){
   // this.addLeadEvent.emit(rowData);
   this.addLeadEvent.emit(rowData);
  //   console.log("addLead")
  //   this.confirmationService.confirm({
  //     message: 'Are you sure that you want to Add To Lead?',
  //     header: 'Confirmation',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {

  //     },
  //     reject: () => {

  //     }
  // });

  }
  myListToLead(rowData:any){
    // console.log("myListToLead Event")
    this.myListToLeadEvent.emit(rowData);
  }

  paginate(event:any){
    // console.log('Paginate');
    // console.log(event);

    this.paginateEvent.emit(event);
  }

  getPredefinedListByType(type: string): any {
    let predefinedMaster = new PredefinedMaster();
    predefinedMaster.entityType = type;
    this.predefinedService.getPredefinedListByType(predefinedMaster).subscribe( data => {
      if (type === 'DATA') {
        this.dataTagList = data;
      } else if (type === 'ASAPP') {
        this.assappTagList = data;
      } else if (type === 'MASTER') {
        this.masterTagListFilter = data;
      } else if (type === 'INDUSTRY') {
        this.subIndustryMasterList = data;
      }
      else if (type === 'INDUSTRY') {
     //   this.industryMasterList = data;
      }else if (type === 'GROUP') {
        this.groupMasterList = data;
      }else if (type === 'LOCATION') {
        this.locationList = data;
      }else if (type === 'DESIGNATION') {
        this.designationMasterList = data;
      }else if (type === 'FUNCTION') {
        this.functionList = data;
      }else if (type === 'DESIGNATIONLEVEL') {
        this.designationLevelMasterList = data;
      }else if (type === 'EQUITY') {
        this.equityList = data;
      }else if (type === 'PREFIX') {
        this.prefixList = data;
      }
    else if (type === 'SOCIALMEDIA') {
      this.socialMediaList = data;
    }
      return data;
     }, error => {
       if (error instanceof HttpErrorResponse) {

         if (error.status === 200) {
            let jsonString = error.error.text;
            jsonString = jsonString.substr(0, jsonString.indexOf('{"timestamp"'));
            return JSON.parse(jsonString);
         } else {
       }

       }
     });
  }


  operation(rowData:any,operationType:string){
    // console.log("operation table event")
    rowData.operationType=operationType;
    this.operationEvent.emit(rowData);
  }

  editModal(rowData:any){
    //console.log("editModal");
    this.editModalEvent.emit(rowData);
  }

  reportModal(rowData:any){
    //console.log("editModal");
    this.reportModalEvent.emit(rowData);
  }
  onFieldSelect(rowData:any){
    this.onSelectModalEvent.emit(rowData);
  }
  onFieldSelectVideo(rowData:any){
    this.onVideoSelectModalEvent.emit(rowData);
  } 
  toggleRow(rowData:any){
  // console.log("toggleRow")
  this.toggleRowEvent.emit(rowData);
   // console.log("radio value "+event.value)
  }

  selectImage(event,rowData: any, overlaypanel: OverlayPanel,fieldName : string) {
    this.selectedRow = rowData;
    this.selectedImage = rowData[fieldName];
    overlaypanel.toggle(event);
  }

  viewSales(rowData:any){
    this.viewSalesEvent.emit(rowData);
  }

  viewQuestion(rowData:any){
    this.viewQuestionEvent.emit(rowData);
  }
}
