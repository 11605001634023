import { ModuleMaster } from './modulemaster.model';
import { Role } from '../../../../../../userapp/src/app/module/login/model/role.model';
import { UserLogin } from '../../../../../../userapp/src/app/module/login/model/userlogin.model';


export class AccessControlMaster{
    public accessControlId:number
    public createAccess:boolean;
    public readAccess:boolean;
    public updateAccess:boolean;
    public deleteAccess:boolean;
    public moduleMaster:ModuleMaster;
    public role:Role;
    public user:UserLogin;
    public staticIpAddress:string;
    public isDelete:string;

    public pageNumber: number;
    public pageSize: number;
    public roleTemp:Role;
    public moduleName:string;
    public roleName:string;

    public employeeId: number;

}
