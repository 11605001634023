import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @Input() modalHeader:string="";
  @Input() modelId:string="";
  @Input() modalWidth:string="";
  @Input() modalBodyTemplate: TemplateRef<any>;
  @Input() modalFooterTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

}
