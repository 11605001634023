import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpUserEvent, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
 import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { SessionClient } from './configuration/sessionclient.storage';
import { tap } from 'rxjs/operators';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/operator/do';
//import { BehaviorSubject } from 'rxjs';


const TOKEN_HEADER_KEY = 'Authorization';
const excludedUrl = [
  'http://ip-api.com/json/',
  'http://localhost:8080/api/fieldConfiguration/getFieldConfigurationListByEntity/'
  ];

@Injectable()
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}


@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(private router: Router,private sessionClient: SessionClient,private status: HTTPStatus) { }

  

  private handleAuthError(err: HttpErrorResponse): Observable<any> {

    if (err.status === 401) {
      
        this.sessionClient.signOut();
        this.router.navigateByUrl('/'); 
     //   return Observable.of(err.message);
   //  return throwError(err);
    }
    if (err.status === 403) {
      
      this.sessionClient.signOut();
      this.router.navigateByUrl('/'); 
   //   return Observable.of(err.message);
 //  return throwError(err);
  }
   // return Observable.throw(err);
   return throwError(err);
}

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      console.log("entered interceptor!!!!!!");

        let authReq = req;
      //  const tokenKey=sessionStorage.getItem(TOKEN_KEY);;
        // console.log("TOKEN "+this.sessionClient.getAccessToken());
        
        if (this.sessionClient.getAccessToken() != null && !excludedUrl.some(x => x === req.url)) {
          authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this.sessionClient.getAccessToken())});
          console.log("true tokenKey found");
        }

     //   return next.handle(authReq).catch(x=> this.handleAuthError(x));
/*
     return next.handle(authReq).do(event => {
      if (event instanceof HttpResponse) {
          console.log(event);
      }
  }).catch(x=> this.handleAuthError(x)) .finally(() => {

}); */
          return next.handle(authReq).pipe(
            tap(event => {
              if (event instanceof HttpResponse) {
              }
            }, error => {
              this.handleAuthError(error)
            //  console.error('ERROR', error)
            })
          )

      }
    
}
