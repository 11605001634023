import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FieldConfiguration } from '../model/fieldconfiguration.model';
import { environment } from 'src/environments/environment';
import { FilterModuleMaster } from '../../common/filter/model/filtermodulemaster.model';


  @Injectable()
  export class FieldConfigurationService {


    constructor(private http: HttpClient) { }

    getFieldConfigurationListByEntity(fieldConfiguration:FieldConfiguration): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/fieldConfiguration/getFieldConfigurationListByEntity',fieldConfiguration);
    }



    forgetPassword(user): Observable<any> {
      //alert("HELLO")
      return this.http.post<any>(environment.apiUrl+'/user/forgetPassword',user);
    }

    saveFieldConfiguration(fieldConfiguration:FieldConfiguration): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/fieldConfiguration/saveFieldConfiguration',fieldConfiguration);
    }

    getFieldConfigurationList(fieldConfiguration:FieldConfiguration): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/fieldConfiguration/getFieldConfigurationList',fieldConfiguration);
    }

    getFieldConfigurationListLength(fieldConfiguration:FieldConfiguration): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/fieldConfiguration/getFieldConfigurationListLength',fieldConfiguration);
    }

    deleteFieldConfiguration(fieldConfiguration:FieldConfiguration): Observable<any> {
      return this.http.delete<any>(environment.apiUrl+'/fieldConfiguration/deleteFieldConfiguration'+fieldConfiguration.fieldConfigurationId);
    }


    getFilterListByModuleAndType(filterModuleMaster:FilterModuleMaster): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/lookup/getFilterListByModuleAndType',filterModuleMaster);
    }


    filterLookupDataBySearchValue(filterModuleMasterData:FilterModuleMaster): Observable<any> {
      let filterModuleMaster=new FilterModuleMaster();
      filterModuleMaster.searchValue1=filterModuleMasterData.searchValue1;
      filterModuleMaster.lookupQuery=filterModuleMasterData.lookupQuery;
      filterModuleMaster.lookupCriteria1=filterModuleMasterData.lookupCriteria1;
      console.log("search value "+filterModuleMaster.searchValue1);
      console.log("lookupQuery value "+filterModuleMaster.lookupQuery);
      console.log("lookupCriteria1 value "+filterModuleMaster.lookupCriteria1);
      return this.http.post<any>(environment.apiUrl+'/lookup/filterLookupDataBySearchValue',filterModuleMaster);
    }

  }
