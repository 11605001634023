import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionClient } from './sessionclient.storage';
import { UserLogin } from 'projects/userapp/src/app/module/login/model/userlogin.model';
 
@Injectable()
export class AuthGuard implements CanActivate {
 
    constructor(private router: Router,private sessionClient:SessionClient) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      //  let userLogin:UserLogin = JSON.parse(this.sessionClient.getSession("userSession"));
        if (this.sessionClient.getToken()!=null) {
            // logged in so return true
            return true;
        }
 
        // not logged in so redirect to login page with the return url
       // this.router.navigateByUrl('/login');
      //  localStorage.removeItem("userSession");
     //   localStorage.removeItem("AuthTokenAdmin");
        this.router.navigateByUrl('/'); 
        return false;
    }
}