import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { PredefinedMaster } from "../model/predefinedmaster.model";
import { environment } from 'src/environments/environment';

@Injectable()
export class PredefinedService {
  constructor(private http: HttpClient) {}

  getPredefinedListByType(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post<any>( environment.apiUrl + "/predefined/getPredefinedListByType", predefinedMaster);
  }

  getPredefinedListByTypeOnlyName(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post<any>( environment.apiUrl + "/predefined/getPredefinedListByTypeOnlyName", predefinedMaster);
  }

  getPredefinedListByEntityTypeAndType(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post<any>( environment.apiUrl + "/predefined/getPredefinedListByEntityTypeAndType", predefinedMaster);
  }


  savePredefinedMaster(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + "/predefined/savePredefined", predefinedMaster);
  }
  updatePredefinedField(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + "/predefined/updatePredefinedField", predefinedMaster);
  }

  

  savePredefinedWithFile(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + "/predefined/savePredefined", predefinedMaster);
  }

  getDesignationEntityListLength(predefinedMaster:PredefinedMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl+'/predefined/getDesignationEntityListLength',predefinedMaster);
}

  deletePredefinedMaster(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + "/predefined/deletePredefinedMaster/"+predefinedMaster.predefinedId);
  }

  filteredPredefinedList(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + "/predefined/getFilteredDesignationNames", predefinedMaster);
  }

  getDocumentPath(file): Observable<any> {
    let formData:FormData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(environment.apiUrl+ "/predefined/getDocumentPath" ,formData);
  }

  getPredefinedByCode(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post<any>( environment.apiUrl + "/predefined/getPredefinedByCode", predefinedMaster);
  }

}
