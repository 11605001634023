export const environment = {
  production: true,
  apiUrl: "https://cnpl.api.savein.money/api",

  region: "ap-south-1",
  userPoolId: "ap-south-1_9KBpGerhS",
  userPoolWebClientId: "583qtid7o5ig1frcb4prsiim0",
  authenticationFlowType: "USER_PASSWORD_AUTH",
  recaptcha: {
    siteKey: "6LeIYssgAAAAAG3KdkNq20laNjCRCpSoV_MXXfW5"
  },
  credolab: {
    url: "https://scoring-in.credolab.com",
    authKey: "65d80f24-51aa-4aad-8614-f424ec1a1a68"
  }
};
