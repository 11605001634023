import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToggleFullScreenDirective} from './fullscreen/toggle-fullscreen.directive';
import {AccordionAnchorDirective} from './accordion/accordionanchor.directive';
import {AccordionLinkDirective} from './accordion/accordionlink.directive';
import {AccordionDirective} from './accordion/accordion.directive';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {TitleComponent} from '../layout/admin/title/title.component';
import {CardComponent} from './card/card.component';
import {CardToggleDirective} from './card/card-toggle.directive';
import {ModalBasicComponent} from './modal-basic/modal-basic.component';
import {ModalAnimationComponent} from './modal-animation/modal-animation.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {ClickOutsideModule} from 'ng-click-outside';
import {DataFilterPipe} from './elements/data-filter.pipe';
import { FormsModule } from '@angular/forms';
import { AuthService } from 'projects/utility/src/app/module/service/auth.service';
import { EmployeeService } from 'projects/userapp/src/app/module/employee/service/employee.service';
import { SessionClient } from '../configuration/sessionclient.storage';
import { MessageService, ConfirmationService } from 'primeng/api';
import { FieldConfigurationService } from 'projects/utility/src/app/module/field/service/fieldconfiguration.service';
import { LookupService } from 'projects/utility/src/app/module/service/lookup.service';
import { Interceptor, HTTPStatus } from '../app.interceptor';
import { AuthGuard } from '../configuration/auth.guard';
import { ToastyService, ToastyConfig, ToastyModule } from 'ng2-toasty';
import { UtilityModule } from 'projects/utility/src/app/utility.module';
import { UiSwitchModule } from 'ng2-ui-switch';
import { PredefinedsettingsComponent } from './predefinedsettings/predefinedsettings.component';
import { PredefinedService } from './predefinedsettings/service/predefined.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import {StepsModule} from 'primeng/steps';
import { UserService } from 'projects/userapp/src/app/module/login/service/user.service';
import { CommonFunctionService } from 'projects/utility/src/app/module/service/common-function.service';
import {RatingModule} from 'primeng/rating';
import {CheckboxModule} from 'primeng/checkbox';
import { ReCaptchaV3Service } from 'ng-recaptcha';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    HttpClientModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    FormsModule,
    UtilityModule,
    UiSwitchModule,
    NgxSpinnerModule,
    StepsModule,
    ToastyModule,
    RatingModule,
    CheckboxModule
    
  ],
  exports: [
    NgbModule,
    ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    HttpClientModule,
    PerfectScrollbarModule,
    TitleComponent,
    CardComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    ClickOutsideModule,
    DataFilterPipe,
    FormsModule,
    UtilityModule,
    UiSwitchModule,
    PredefinedsettingsComponent,
    NgxSpinnerModule,
    StepsModule,
    ToastyModule,
    RatingModule,
    CheckboxModule
    
  ],
  declarations: [
    ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    TitleComponent,
    CardComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    DataFilterPipe,
    PredefinedsettingsComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi : true},
    HTTPStatus,
    AuthService,
    EmployeeService,
    SessionClient,
    MessageService,
    FieldConfigurationService,
    LookupService,
    AuthGuard,
    ConfirmationService,
    PredefinedService,
    ToastyService,
    ToastyConfig,
    UserService,
    CommonFunctionService,
    DatePipe,
    ReCaptchaV3Service
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class SharedModule { }
