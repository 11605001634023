import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { PredefinedMaster } from './model/predefinedmaster.model';
import { AuthService } from 'projects/utility/src/app/module/service/auth.service';
import { PredefinedService } from './service/predefined.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { VendorService } from "projects/utility/src/app/module/service/vendor.service";
import { DocumentMaster } from "projects/userapp/src/app/module/login/model/DocumentMaster.model";

interface dropdownValue{
  "name":string;
}

@Component({
  selector: "app-predefinedsettings",
  templateUrl: "./predefinedsettings.component.html",
  styleUrls: ["./predefinedsettings.component.css"],
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({opacity: 0}),
        animate('400ms ease-in-out', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'translate(0)'}),
        animate('400ms ease-in-out', style({opacity: 0}))
      ])
    ])
  ]
})
export class PredefinedsettingsComponent implements OnInit {
  @ViewChild('modalPredefined') modalPredefined:any;
  predefinedMaster: PredefinedMaster = new PredefinedMaster();
  name: string;
  totalRecords: number = 0;
  pageSize: number = 10;
  pageNumber: number = 0;

  projectCols: any[];
  typeCols: any[];
  categoryCols: any[];
  amenitiesCols: any[];

  projectList: PredefinedMaster[]=[];
  typeList: PredefinedMaster[]=[];
  categoryList: PredefinedMaster[]=[];
  amenitiesList: PredefinedMaster[]=[];
  dropdownList:dropdownValue[]=[];
  defaultCity:PredefinedMaster = new PredefinedMaster();
  defaultCityList:PredefinedMaster[]=[];
  subCategoryList:PredefinedMaster[]=[];
   validateCheck:boolean = false;
   panCardImagePath: any[] = [];
   uploadProfileFiles: any[] = [];
   validateCheckTax:boolean=false;
   validateDelivery:boolean=false;
   showProfileimage:boolean=false;
   @ViewChild('uploadImage') uploadImage: ElementRef;

   userDocumentMaster:DocumentMaster=new DocumentMaster();
  constructor(
    private predefinedService: PredefinedService,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    public vendorService: VendorService
  ) {}

  ngOnInit() {
      this.getPredefinedListByType("PROJECT");
      this.getPredefinedListByType("AMENITIES");
      this.getPredefinedListByType("CATEGORIES");
     this.getPredefinedByCode("COMPANY-INFO");
     this.getPredefinedListByType("MENU-SUB-CATEGORY");
      this.setTableSetting();
      this.dropdownList.push({
        "name":"House"
      },{
        "name":"Society"
      })
      
  }

  setTableSetting(){

    this.projectCols=[   
      {filterfield:'name',field:'name',header:'Name',width : '300px',fieldType:'text',columnType:'data'}, 
      // {filterfield:'subName',field:'subName',header:'Location',width : '300px',fieldType:'text',columnType:'data'}, 
      {filterfield:'filePath',field:'filePath',header:'Image',width : '300px',fieldType:'image',columnType:'data'}
    ];

    this.amenitiesCols=[   
      {filterfield:'name',field:'name',header:'Name',width : '100px',fieldType:'text',columnType:'data'}, 
      {filterfield:'type',field:'type',header:'Type',width : '100px',fieldType:'text',columnType:'data'}
      // {filterfield:'filePath',field:'filePath',header:'Icon',width : '100px',fieldType:'image',columnType:'data'}  
    ];

    this.categoryCols=[   
      {filterfield:'name',field:'name',header:'Name',width : '100px',fieldType:'text',columnType:'data'}, 
      // {filterfield:'displayOrder',field:'displayOrder',header:'Display Order',width : '100px',fieldType:'number',columnType:'data'}, 
      // {filterfield:'filePath',field:'filePath',header:'Image',width : '100px',fieldType:'image',columnType:'data'}
    ];


    this.typeCols=[   
      {filterfield:'name',field:'name',header:'Name',width : '100px',fieldType:'text',columnType:'data'}
    ];

  } 
 

  getPredefinedListByType(type: string): any {
  //  this.spinnerService.show();
    let predefinedMaster = new PredefinedMaster();
    predefinedMaster.entityType = type;
    if (type === "CATEGORIES") {
      predefinedMaster.sortOrder ="asc";
    }
    this.predefinedService.getPredefinedListByType(predefinedMaster).subscribe(
      data => {
        if (type === "PROJECT") {predefinedMaster.typeData
          this.projectList = data;
        }else if (type === "AMENITIES") {
          this.amenitiesList = data;
         
        }else if (type === "CATEGORIES") {
          this.categoryList = data;
        }else if (type === "PROPERTYTYPE") {
          this.typeList = data;
        }  
        else if (type === "MENU-SUB-CATEGORY") {
          this.subCategoryList = data;
        }  
      },
      error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 200) {
            let jsonString = error.error.text;
            jsonString = jsonString.substr(
              0,
              jsonString.indexOf('{"timestamp"')
            );
         //   this.spinnerService.hide();
            return JSON.parse(jsonString);
          } else {
            this.authService.showMessage('error','Something went wrong', '');
          //  this.spinnerService.hide();
          }
        }
      }
    );
  }

  myPredefinedMasterModal(type: string) {
    this.predefinedMaster = new PredefinedMaster();
    this.predefinedMaster.entityType = type;
    if(type === "LOCATION"){
      this.name = 'City';
    } else if(type === 'INDUSTRY'){
      this.name = 'Sub Industry';
    } else {
      this.name = type;
    }
  }

 

  savePredefined() {
    this.spinnerService.show();
    let validateCheckStr = "";
    let validateCheck = false;

    if(this.predefinedMaster.field3 === null || this.predefinedMaster.field3 === undefined || this.predefinedMaster.field3 === ""){
      this.validateCheckTax = true;
      return;
      // validateCheckStr += "Name  ";
    }
    else{
      this.validateCheckTax = false;
    }

    this.predefinedService.savePredefinedMaster(this.predefinedMaster).subscribe(
      data => {
        
       
          this.getPredefinedByCode("COMPANY-INFO");
        
        this.modalPredefined.hide();
        this.spinnerService.hide();
      },
      error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 500) {
            // console.log(error.error.text);
            //json circular break at server side always add
            //extra information of timestamp and status along with
            //main json so parsing occurs still at status 200
            this.spinnerService.hide();
          } else {
            this.authService.showMessage('error','Something Went Wrong','');
            this.spinnerService.hide();
          }
          this.modalPredefined.hide();
        }
      }
    );
  }

  updatePredefined(type:string) {

    let validateCheckStr = "";
    let validateCheck = false;

  if(type=="field4"){
    if(this.predefinedMaster.field4 === null || this.predefinedMaster.field4 === undefined || this.predefinedMaster.field4 === ""){
      this.validateCheckTax = true;
      return;
      // validateCheckStr += "Name  ";
    }
    else{
      this.validateCheckTax = false;
    }
   
    this.predefinedMaster.fieldName="field4";
  }else if(type=="field5"){

    if(this.predefinedMaster.field5 === null || this.predefinedMaster.field5 === undefined || this.predefinedMaster.field5 === ""){
      this.validateDelivery = true;
      return;
      // validateCheckStr += "Name  ";
    }
    else{
      this.validateDelivery = false;
    }
    
    this.predefinedMaster.fieldName="field5";
  }else{
    return;
  }

   
  this.predefinedMaster.folderName ="other";
    this.predefinedService.updatePredefinedField(this.predefinedMaster).subscribe(
      data => {
        
        this.predefinedMaster=data;
        swal({
          title: 'Suceess',
          text: 'Success',
          type: 'success',
          confirmButtonClass: 'btn btn-success m-r-10',
          cancelButtonClass: 'btn btn-primary',
  
        })
          this.getPredefinedByCode("COMPANY-INFO");
          this.modalPredefined.hide();
          if(type=="field4"){
            this.validateCheckTax = false;
          }else if(type=="field5"){
            this.validateDelivery = false;
          }
     
      },
      error => {
        if (error instanceof HttpErrorResponse) {
          if(error.status==200){
            swal({
              title: 'Suceess',
              text: 'Success',
              type: 'success',
              confirmButtonClass: 'btn btn-success m-r-10',
              cancelButtonClass: 'btn btn-primary',
      
            })
              this.getPredefinedByCode("COMPANY-INFO");
              this.modalPredefined.hide();
          
          }
          else if (error.status === 500) {
            // console.log(error.error.text);
            //json circular break at server side always add
            //extra information of timestamp and status along with
            //main json so parsing occurs still at status 200
           
          } else {
            this.authService.showMessage('error','Something Went Wrong','');
            
          }
          
        }
      }
    );
  }
  addPredefinedModal(entity:string){
    this.predefinedMaster = new PredefinedMaster(); 
    this.predefinedMaster.entityType = entity;
    // this.predefinedMaster.rowId=Math.random()+"";  
    this.modalPredefined.show();
  }
  updatePredefinedModal(updatePredefinedMaster: PredefinedMaster){
    this.predefinedMaster = new PredefinedMaster(); 
    this.predefinedMaster = updatePredefinedMaster; 

    if(updatePredefinedMaster.type!=null && updatePredefinedMaster.type!=undefined){
      updatePredefinedMaster.typeData= {
        "name":updatePredefinedMaster.type
      };
    }

    this.modalPredefined.show();
  }


  updatePredefinedList(){
    this.spinnerService.show();
    this.predefinedService.savePredefinedMaster(this.predefinedMaster).subscribe(
      data => {
        this.authService.addToast({title:'', msg:'Updated Successfully', showClose: true, 
        timeout: 5000, theme:'default', type:'error', position:'bottom-left', closeOther:true});
       // this.authService.showMessage("success","Added Successfully to list","");
         if(data.entityType === 'PROJECT'){
          this.getPredefinedListByType("PROJECT");
        }else if(data.entityType === 'AMENITIES'){
          this.getPredefinedListByType("AMENITIES");
        }else if(data.entityType === 'CATEGORIES'){
          this.getPredefinedListByType("CATEGORIES");
        }else if(data.entityType === 'PROPERTYTYPE'){
          this.getPredefinedListByType("PROPERTYTYPE");
        }
        
        this.spinnerService.hide();
      },
      error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 500) {
            // console.log(error.error.text);
            //json circular break at server side always add
            //extra information of timestamp and status along with
            //main json so parsing occurs still at status 200
            this.spinnerService.hide();
          } else {
            this.authService.showMessage('error','Something Went Wrong','');
            this.spinnerService.hide();
          }
        }
      }
    );
  }

  savePredefinedWithFile(){
    // this.spinnerService.show();
    let validateCheckStr = "";
    

    // if(this.predefinedMaster.typeData!=null && this.predefinedMaster.typeData!=undefined){
    //   this.predefinedMaster.type = this.predefinedMaster.typeData.name;
    // }


    if(this.predefinedMaster.name === null || this.predefinedMaster.name === undefined || this.predefinedMaster.name === ""){
      this.validateCheck = true;
     
      // validateCheckStr += "Name  ";
    }
    else{
      this.validateCheck = false;
    }
    if((this.predefinedMaster.actualPath == null || this.predefinedMaster.actualPath  == undefined) && (this.predefinedMaster.field2 ==null || this.predefinedMaster.field2==undefined)){
      this.showProfileimage = true;
     
      // validateCheckStr += "Name  ";
    }
    else{
      this.showProfileimage = false;
    }
    if(this.validateCheck || this.showProfileimage){
      return;
    }
  
    this.predefinedMaster.entityType="MENU-SUB-CATEGORY";
    this.predefinedMaster.folderName ="other";
    this.predefinedMaster.fieldName="field3";

    this.predefinedService.savePredefinedWithFile(this.predefinedMaster).subscribe(
      data => {
        this.getPredefinedListByType("MENU-SUB-CATEGORY");
       // this.authService.showMessage("success","Added Successfully to list","");
       swal({
        title: 'Suceess',
        text: 'Sub Category Save Successfully',
        type: 'success',
        confirmButtonClass: 'btn btn-success m-r-10',
        cancelButtonClass: 'btn btn-primary',

      })
       this.modalPredefined.hide();
        this.spinnerService.hide();
      },
      error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 200) {
            // console.log(error.error.text);
            //json circular break at server side always add
            //extra information of timestamp and status along with
            //main json so parsing occurs still at status 200
            this.getPredefinedListByType("MENU-SUB-CATEGORY");
            swal({
              title: 'Suceess',
              text: 'Success',
              type: 'success',
              confirmButtonClass: 'btn btn-success m-r-10',
              cancelButtonClass: 'btn btn-primary',
      
            })
            this.modalPredefined.hide();
            this.spinnerService.hide();
          } else {
            this.authService.showMessage('error','Something Went Wrong','');
            this.spinnerService.hide();
            this.modalPredefined.hide();
          }
          this.modalPredefined.hide();
        }
      }
    );
  }

  updatePredefinedWithFile(){
    // this.spinnerService.show();
    let validateCheckStr = "";
    

    // if(this.predefinedMaster.typeData!=null && this.predefinedMaster.typeData!=undefined){
    //   this.predefinedMaster.type = this.predefinedMaster.typeData.name;
    // }


    if(this.predefinedMaster.name === null || this.predefinedMaster.name === undefined || this.predefinedMaster.name === ""){
      this.validateCheck = true;
      return;
      // validateCheckStr += "Name  ";
    }
    else{
      this.validateCheck = false;
    }

  
    this.predefinedMaster.entityType="MENU-SUB-CATEGORY";
    this.predefinedMaster.folderName ="other";
    this.predefinedMaster.fieldName="field3";

    this.predefinedService.updatePredefinedField(this.predefinedMaster).subscribe(
      data => {

        // this.authService.showMessage("success","Added Successfully to list","");
      
        
        this.spinnerService.hide();
      },
      error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 200) {
            // console.log(error.error.text);
            //json circular break at server side always add
            //extra information of timestamp and status along with
            //main json so parsing occurs still at status 200
            this.getPredefinedListByType("MENU-SUB-CATEGORY");
            this.spinnerService.hide();
          } else {
            this.authService.showMessage('error','Something Went Wrong','');
            this.spinnerService.hide();
          }
          this.modalPredefined.hide();
          swal({
            title: 'Suceess',
            text: 'Success',
            type: 'success',
            confirmButtonClass: 'btn btn-success m-r-10',
            cancelButtonClass: 'btn btn-primary',
    
          })
        }
      }
    );
  }
  deletePredefinedMaster(predefinedMaster: PredefinedMaster): void {

    let message=" delete";
    
    swal({
      title: 'Are you sure, '+message+' ?',
      text: 'You wont be able to revert',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#C40000',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, '+message+' it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonClass: 'btn btn-success m-r-10',
      cancelButtonClass: 'btn btn-primary',
      buttonsStyling: false
    }).then( (reponse)=>{ 
      console.log("dismiss "+reponse);
  
      if (reponse.dismiss!=null && reponse.dismiss!=undefined) {   
        swal(
          'Cancelled',
          '',
          'error'
        );

      }else{
      

        this.spinnerService.show();
        this.predefinedService.deletePredefinedMaster(predefinedMaster).subscribe(data => {
         // this.authService.showMessage('success', 'Deleted Successfully', '');
         swal(
          message+' !',
          'You Have Successfully Deleted',
          'success'
        );
          console.log(predefinedMaster);
          this.getPredefinedListByType("MENU-SUB-CATEGORY");

          this.spinnerService.hide();
        }, error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 200) {
              // console.log(error.error.text);
              //json circular break at server side always add
              //extra information of timestamp and status along with
              //main json so parsing occurs still at status 200
             // this.authService.showMessage('success', 'Deleted Successfully', '');
             this.getPredefinedListByType("MENU-SUB-CATEGORY");

             swal(
              message+' !',
              'You Have Successfully Deleted',
              'success'
            );
            
              this.spinnerService.hide();
            } else {
              this.authService.showMessage('error', 'Something Went wrong', '');
              this.spinnerService.hide();
            }
          }
        })

     
      }
      
    }).catch(swal.noop);

  }

  openBrowse(name,id){
    console.log("openBrowse");
    (<HTMLInputElement>document.getElementById(name+id)).click();
  }



  public onFileChange(rowData:any,event){
    console.log("onFileChange");
    let maxSize = 2000000;
    let fileSize=event.target.files[0].size;
    if(fileSize > maxSize){
      alert("Max File size should not exceed more than 2mb");
 
      event.target.value="";
      rowData.fileName="";
      rowData.documentSelectedPath="";
      return;
    }

    rowData.file=event.target.files[0];
    rowData.fileName=rowData.file.name;
    console.log("path from server data  "+rowData.fileName);
  
    this.predefinedService.getDocumentPath(rowData.file).subscribe( data => {
      console.log("path from server data  "+data);
      rowData.documentSelectedPath=data.documentSelectedPath;  
      rowData.newFileName = data.newFileName;
      rowData.fileName = data.fileName;
      console.log("newFileName "+rowData.newFileName);
      rowData.fileData=event.files;
  },error =>{
    if (error instanceof HttpErrorResponse) {
  
      alert("Something went wrong.");
    
    }
  });
  
  }


  savePredefinedCommon(){
    if(this.predefinedMaster.entityType=="COMPANY-INFO"){
      this.savePredefined();
    }else {
      this.savePredefinedWithFile();
    }

  }
  
  getPredefinedByCode(code: string) {
    let predefinedMaster = new PredefinedMaster();
    predefinedMaster.code = code;
    this.defaultCityList =[];

    this.predefinedService.getPredefinedByCode(predefinedMaster).subscribe(data => {
      
        this.predefinedMaster = data;
        // this.defaultCityList.push(this.defaultCity);
        
    

    }, (err: HttpErrorResponse) => {
      if (err.status === 200) {
        let jsonString = err.error.text;

      }


    })
  }
  uploadProfileImage(event: any, fileType: string) {
    console.log(event,"event");
    for (let file of event.target.files) {
      this.uploadProfileFiles=[];

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = event => {

        // this.panCardImagePath.push(reader.result);

      };

      this.uploadProfileFiles.push(file);
    }
    this.getDocumentPathProfile(this.uploadProfileFiles);
  }
  getDocumentPathProfile(file){
    console.log(file,"file[0].size");
    if (file[0].size > 1048576) {
      swal({
        title: 'Error',
        text: 'File size should be less than 1MB !',
        type: 'error',
        confirmButtonClass: 'btn btn-success m-r-10',
        cancelButtonClass: 'btn btn-primary',

      })
      return;
    }
    this.vendorService.getDocumentPath(this.uploadProfileFiles).subscribe(data => {
      let documentMaster: DocumentMaster = data;
      console.log(documentMaster, "documentMaster");
      this.predefinedMaster.actualPath = "/tempfolder" + "/" + documentMaster.documentName;
      // this.predefineMaster.documentMaster = documentMaster;
      console.log(documentMaster.fullPath,"documentMaster.fullPath");
      this.predefinedMaster.documentName = documentMaster.documentName;
      // this.banner.type=documentMaster.fileType;
      // this.banner.actualPath = documentMaster.actualPath;
      this.predefinedMaster.documentSelectedPath = documentMaster.documentSelectedPath;
      this.spinnerService.hide();

      this.uploadProfileFiles = [];
      // this.uploadImage.nativeElement.clear();
      this.uploadImage.nativeElement.value = '';
      // this.uploadVideo.nativeElement.value = '';

    }, (err: HttpErrorResponse) => {
      if (err.status === 200) {
        let jsonString = err.error.text;


      }


    })
  }
  keyPressAlphaNumericWithCharacters(event) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[a-zA-Z0-9-.',._ ]/.test(inp)){
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
NumericWithCharacters(event) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9%. ]/.test(inp)){
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}