import { Component, OnInit, Input } from '@angular/core';
import { FieldConfiguration } from '../model/fieldconfiguration.model';

@Component({
  selector: 'app-fieldconfiguration',
  templateUrl: './fieldconfiguration.component.html',
  styleUrls: ['./fieldconfiguration.component.scss']
})
export class FieldConfigurationComponent implements OnInit {

  @Input() fieldConfigurationList:FieldConfiguration[]=[];
  @Input() masterData:any=null;
  
  constructor() { }

  ngOnInit() {
  }

}
