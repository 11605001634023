import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { EmployeeCompanyMaster } from 'projects/userapp/src/app/module/employee/model/employeecompanymaster.model';
import { EmployeeBranchMaster } from 'projects/userapp/src/app/module/employee/model/employeebranchmaster.model';
import { EmployeeDepartmentMaster } from 'projects/userapp/src/app/module/employee/model/employeedepartmentmaster.model';
import { EmployeeDesignationMaster } from 'projects/userapp/src/app/module/employee/model/employeedesginationmaster.model';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'Application/json; charset=UTF-8'
  }),
  responseType: 'blob' as 'blob'
};

const httpOptionsfile = {
  headers: new HttpHeaders({
   'Access-Control-Allow-Origin':'*',
   'Accept': 'application/json'})
};

@Injectable()
  export class LookupService {

  constructor(private http: HttpClient) { }

  getEmployeeCompanyList(employeeCompanyMaster:EmployeeCompanyMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl+'/lookup/getEmployeeCompanyList',employeeCompanyMaster);
  }

  getEmployeeBranchList(employeeBranchMaster:EmployeeBranchMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl+'/lookup/getEmployeeBranchList',employeeBranchMaster);
  }

  getEmployeeDepartmentList(employeeDepartmentMaster:EmployeeDepartmentMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl+'/lookup/getEmployeeDepartmentList',employeeDepartmentMaster);
  }

  getEmployeeDesignationList(employeeDesignationMaster:EmployeeDesignationMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl+'/lookup/getEmployeeDesignationList',employeeDesignationMaster);
  }

  getRoleList(): Observable<any> {
    return this.http.get<any>(environment.apiUrl+'/role/getRoleList');
  }



}
