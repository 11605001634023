import { EmployeeCompanyMaster } from './employeecompanymaster.model';
import { EmployeeBranchMaster } from './employeebranchmaster.model';
import { EmployeeDepartmentMaster } from './employeedepartmentmaster.model';
import { EmployeeDesignationMaster } from './employeedesginationmaster.model';
import {LookupData} from '../../../../../../utility/src/app/module/common/filter/model/lookupdata.model';

export class EmployeeMaster {
    public employeeId: number;
    public employeeName: string;
    public employeeCode: string;
    public emailAddress: string;
    public mobileNo: string;
    public password: string;
    public city: string;
    public confirmPassword: string;
    public createUser:boolean;
    public lattitude:string;
    public longitude:string;
    public isDelete: string;
    public isDeactive:string;
    public employeeCompanyMaster:EmployeeCompanyMaster;
    public employeeBranchMaster:EmployeeBranchMaster;
    public employeeDepartmentMaster:EmployeeDepartmentMaster;
    public employeeDepartmentMasterList:EmployeeDepartmentMaster[];


    public employeeDesignationMaster:EmployeeDesignationMaster;
    public roleName: string;

    public deactive:LookupData;
    public role:LookupData;
    //public employeeStatus: LookupData;

    public employeeDesignation:string;

    public pageNumber: number;
    public pageSize: number;

    public createdBy: EmployeeMaster;
    public modifiedBy: EmployeeMaster;



}
