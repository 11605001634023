import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AccessControlMaster } from '../system/model/accesscontrolmaster.model';
import { map } from 'rxjs/operators';
import { PropertiesDTO } from '../system/model/propertiesdto.model';
// import { EmployeeMaster } from '../../../../../userapp/src/app/module/employee/model/employeemaster.model'
// import { UserLogin } from 'projects/userapp/src/app/module/login/model/userlogin.model';
import { SessionClient } from 'src/app/configuration/sessionclient.storage';
import { ToastyService, ToastOptions, ToastData } from 'ng2-toasty';
// import { EnquiryMaster } from 'projects/userapp/src/app/module/login/model/EnquiryMaster.model';


const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*'
  })
};


@Injectable()
export class AuthService {
  // user: UserLogin = new UserLogin();
  // employeeMaster: EmployeeMaster = new EmployeeMaster();
  accessControlMasterList: AccessControlMaster[] = [];
  accessControlMasterMap: { [moduleCode: string]: AccessControlMaster; } = {};
  properties: PropertiesDTO = new PropertiesDTO();
  localIpAddress: string = "";
  publicIpAddress: string = "";
  ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);
  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  // enquiryMaster: EnquiryMaster = new EnquiryMaster();
  constructor(private http: HttpClient, private sessionClient: SessionClient, private messageService: MessageService,
    private zone: NgZone, private toastyService: ToastyService) {

  }



  attemptAuth(user): Observable<any> {
    console.log('attempAuth ::');
    return this.http.post<any>(environment.apiUrl + '/token/generate-token', user);
  }



  totalUserCount(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/user/totalUserCount');
  }


  // forgetPassword(user:UserLogin):Observable<any>{
  //   return this.http.post<any>(environment.apiUrl+'/user/forgetPassword/'+user.username);
  // }

  forgetPassword(user): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/user/forgetPassword/', user);
  }

 


  







  setProperties(properties: PropertiesDTO) {
    this.properties = properties;
    // sessionStorage.setItem("properties",  JSON.stringify(this.properties));
    localStorage.setItem("properties", JSON.stringify(this.properties));
  }

  getProperties() {
    //  if(this.properties===null || this.properties===undefined ){
    //  this.properties=JSON.parse(sessionStorage.getItem("properties"));
    this.properties = JSON.parse(localStorage.getItem("properties"));
    // }
    return this.properties;
  }

  setMenu(menuItems: any) {
    localStorage.setItem("menu", JSON.stringify(menuItems));
  }

  getMenu() {
    return JSON.parse(localStorage.getItem("menu"));
  }


  setAccessControlData(accessControlMasterList: AccessControlMaster[]) {
    this.accessControlMasterList = accessControlMasterList;
    for (let accessControlMaster of this.accessControlMasterList) {
      this.accessControlMasterMap[accessControlMaster.moduleMaster.moduleCode] = accessControlMaster;
    }


    //sessionStorage.setItem("accessControlMapData",  JSON.stringify(this.accessControlMasterMap));
    // sessionStorage.setItem("accessControlListData",  JSON.stringify(this.accessControlMasterList));

    localStorage.setItem("accessControlMapData", JSON.stringify(this.accessControlMasterMap));
    localStorage.setItem("accessControlListData", JSON.stringify(this.accessControlMasterList));

  }


  getAccessControlListData() {
    console.log("getAccessControlListData");
    if (this.accessControlMasterList === null || this.accessControlMasterList === undefined || this.accessControlMasterList.length === 0) {
      //  this.accessControlMasterList=JSON.parse(sessionStorage.getItem("accessControlListData"));
      // this.accessControlMasterMap=JSON.parse(sessionStorage.getItem("accessControlMapData"));
      this.accessControlMasterList = JSON.parse(localStorage.getItem("accessControlListData"));
      this.accessControlMasterMap = JSON.parse(localStorage.getItem("accessControlMapData"));
    }

    return this.accessControlMasterList;
  }

  getAccessControlMapData() {
    //  console.log("getAccessControlMapData");
    if (this.accessControlMasterList === null || this.accessControlMasterList === undefined || this.accessControlMasterList === undefined || this.accessControlMasterList.length === 0) {
      //  this.accessControlMasterList=JSON.parse(sessionStorage.getItem("accessControlListData"));
      // this.accessControlMasterMap=JSON.parse(sessionStorage.getItem("accessControlMapData"));
      this.accessControlMasterList = JSON.parse(localStorage.getItem("accessControlListData"));
      this.accessControlMasterMap = JSON.parse(localStorage.getItem("accessControlMapData"));
    }

    return this.accessControlMasterMap;
  }

  getIpAddress(): Observable<any> {
    return this.http
      .get("http://ip-api.com/json/");
  }

  public getPublicIpAddress() {
    this.getIpAddress().subscribe(data => {
      // console.log(data);
      try {
        this.publicIpAddress = data.query;
        console.log("publicIpAddress " + this.publicIpAddress);
      } catch (err) {

      }

    }, (error: HttpErrorResponse) => {
      console.error('Currently Offline: ', error);
    });
  }

  public getLocalIpAddressData() {
    return this.localIpAddress;
  }

  public getPublicIpAddressData() {
    return this.publicIpAddress;
  }

  showMessage(severityData: string, summaryData: string, detailData: string) {
    this.messageService.add({ severity: severityData, summary: summaryData, detail: detailData });
  }


  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }


  }



}
