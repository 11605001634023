import { SafeResourceUrl } from "@angular/platform-browser";

export class DocumentMaster {
    public documentId: number;
	public documentName: string;
	public filePath: string;
	public fileKey: string;
	public fileType: string;
	public entityName: string;
	public documentNameThumbnail: string;
	public s3KeyThumbnail: string;
	public filePathThumbnail: string;
	public documentSelectedPath: string;
    public isDelete: string;
    public createdDate:Date;
	public modifiedDate:Date;
	public fullPath: string;
	public actualPath: string;
	public   documentPath:string;
	public   documentKey:string;
	public   link:string;
	public   linkId:string;
	public title:string;
	public description:string;
	public  safeLink:SafeResourceUrl;
	public  safeLinkStr:string;
	public url:any;

}