import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { AppComponent } from './app.component';
import { UtilityRoutingModule } from './utility-routing.module';
import { TableComponent } from '../app/module/common/table/table.component';
import {TableModule} from 'primeng/table';
import { ModalComponent } from './module/common/modal/modal.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule} from 'primeng/checkbox';
import { CalendarModule} from 'primeng/calendar';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './module/common/filter/filter.component';
import {InputTextModule} from 'primeng/inputtext';
import { StepsComponent } from './module/common/steps/steps.component';
import { FieldConfigurationComponent } from './module/field/fieldconfiguration/fieldconfiguration.component';
import { AccesscontrolmasterComponent } from './module/system/accesscontrolmaster/accesscontrolmaster.component';
import { ModulemasterComponent } from './module/system/modulemaster/modulemaster.component';
import {StepsModule} from 'primeng/steps';
import {RadioButtonModule} from 'primeng/radiobutton';
import {GMapModule} from 'primeng/gmap';
import {EditorModule} from 'primeng/editor';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { UiSwitchModule } from 'ng2-ui-switch';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {FileUploadModule} from 'primeng/fileupload';
import {InputSwitchModule} from 'primeng/inputswitch';
import { QuillEditModule } from 'src/app/theme/extension/editor/quill-edit/quill-edit.module';
import { QuillEditorModule } from 'ngx-quill-editor';
import { ReactiveFormsModule } from '@angular/forms';
import {DialogModule} from 'primeng/dialog';


@NgModule({
  declarations: [
    AppComponent,
    TableComponent,
    ModalComponent,
    FilterComponent,
    StepsComponent,
    FieldConfigurationComponent,
    AccesscontrolmasterComponent,
    ModulemasterComponent,
  ],
  imports: [
    CommonModule,
    UtilityRoutingModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    PaginatorModule,
    ConfirmDialogModule,
    CheckboxModule,
    CalendarModule,
    InputTextModule,
    StepsModule,
    RadioButtonModule,
    GMapModule,
    EditorModule,
    ToastModule,
    TooltipModule,
    OverlayPanelModule,
    UiSwitchModule,
    ScrollPanelModule,
    FileUploadModule,
    InputSwitchModule,
    
 
  ],
  exports: [
    TableModule,
    ModalComponent,
    TableComponent,
    FilterComponent,
    MultiSelectModule,
    DropdownModule,
    PaginatorModule,
    ConfirmDialogModule,
    CheckboxModule,
    CalendarModule,
    InputTextModule,
    StepsModule,
    RadioButtonModule,
    GMapModule,
    AccesscontrolmasterComponent,
    EditorModule,
    ToastModule,
    TooltipModule,
    OverlayPanelModule,
    UiSwitchModule,
    ScrollPanelModule,
    FileUploadModule,
    InputSwitchModule,
    DialogModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class UtilityModule { }

