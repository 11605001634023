import { PredefinedMaster } from 'src/app/shared/predefinedsettings/model/predefinedmaster.model';
import { EnquiryCheckListMaster } from './EnquiryCheckListMaster.model';
import { UserLogin } from './userlogin.model';

export class EnquiryMaster {
    public enquiryId: number;
    public name: string;
    public email: string;
    public phone: string;
     public isDelete: string;
    public isDeactive: string;
    public assignTo: UserLogin;
    public assignToId: number;
    public stage: PredefinedMaster;
    public stageId: number;
    public createdDate: number;
    public modifiedDate: number;
    public enquiryCheckListDataList:EnquiryCheckListMaster[]=[];	
    public statusCheck:number;
    public pageNumber:number;
    public pageSize:number;
    public steps:any[]=[];
     public  mapAddress:string;
    public  lat:number;
    public  lng:number;
    public status:PredefinedMaster;
    public statusId:number;
    public recordSize:number;
    public  address:string;
    public stepsOuter:any[]=[];
    public vendorCode:string;
    public searchValue:string;
    public comments:string;
    public city:PredefinedMaster;
    public pincode:string
    public  commission:PredefinedMaster;
    public  commissionId:number;
    public   userAccountDetailsId:number;
    public   user:UserLogin;
    public   userId:number;
    public   roleName:string;
    public   entityType:string;
    public   accountHolderName:string;
    public   accountNo:string;        
    public   expiryDate:Date;
    public   bankName:string;        
    public   ifscCode:string;        
    public   branchName:string;        
    public   contactNo:string;        
    public   cvvNo:string;        
    public   upiId:string;
    public   lastId:number;
    public speciality:string;
    public commissionRate:number;

}

