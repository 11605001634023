import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientMaster } from 'projects/userapp/src/app/module/login/model/ClientMaster.model';
import { DocumentMaster } from 'projects/userapp/src/app/module/login/model/DocumentMaster.model';
import { EnquiryCheckListMaster } from 'projects/userapp/src/app/module/login/model/EnquiryCheckListMaster.model';
import { EnquiryDocument } from 'projects/userapp/src/app/module/login/model/EnquiryDocument.model';
import { EnquiryMaster } from 'projects/userapp/src/app/module/login/model/EnquiryMaster.model';
import { EnquiryStatusLog } from 'projects/userapp/src/app/module/login/model/EnquiryStatusLog.model';
import { OrderMaster } from 'projects/userapp/src/app/module/login/model/OrderMaster.model';
import { VendorDocument } from 'projects/userapp/src/app/module/login/model/VendorDocument.model';
import { VendorMaster } from 'projects/userapp/src/app/module/login/model/VendorMaster.model';
import { VendorMenu } from 'projects/userapp/src/app/module/login/model/VendorMenu.model';
import { VendorMenuDocument } from 'projects/userapp/src/app/module/login/model/VendorMenuDocument.model';
import { Observable } from 'rxjs';
import { SessionClient } from 'src/app/configuration/sessionclient.storage';
import { PredefinedMaster } from 'src/app/shared/predefinedsettings/model/predefinedmaster.model';
import { environment } from 'src/environments/environment';
import { VendorMenuOptions } from 'projects/userapp/src/app/module/login/model/VendorMenuOptions.model';
import { VendorMenuAddons } from 'projects/userapp/src/app/module/login/model/VendorMenuAddons.model';
import { DocumentRequest } from 'projects/userapp/src/app/module/login/model/DocumentRequest.model';
import { MenuRequest } from 'projects/userapp/src/app/module/login/model/MenuRequest.model';
import { ClientRequest } from 'projects/userapp/src/app/module/login/model/ClientRequest.model';
import { UserKeywordSearch } from 'projects/userapp/src/app/module/login/model/UserKeywordSearch.model';
import { UserRule } from 'projects/userapp/src/app/module/login/model/UserRule.model';
import { RuleConfig } from 'projects/userapp/src/app/module/login/model/RuleConfig.model';
import { CategoryConfig } from 'projects/userapp/src/app/module/login/model/CategoryConfig.model';
import { CategorySubConfig } from 'projects/userapp/src/app/module/login/model/CategorySubConfig.model';
import { UserAddress } from 'projects/userapp/src/app/module/login/model/UserAddress.model';
import { ClientReview } from 'projects/userapp/src/app/module/login/model/ClientReview.model';
import { Banner } from 'projects/userapp/src/app/module/login/model/banner.model';
import { UserAccountDetails } from 'projects/userapp/src/app/module/login/model/UserAccountDetails.model';
import { NotificationMaster } from 'projects/userapp/src/app/module/login/model/NotificationMaster.model';

const httpOptions_FILE = {
  headers: new HttpHeaders({
    'Content-Type': 'Application/json; charset=UTF-8'
  }),
  responseType: 'blob' as 'blob'
};
@Injectable({
  providedIn: 'root'
})

export class VendorService {
  enquiryMaster:EnquiryMaster = new EnquiryMaster();
  orderMaster:OrderMaster = new OrderMaster();
  clientMaster:ClientMaster = new ClientMaster();
  vendorMaster:VendorMaster=new VendorMaster();
  constructor(private http: HttpClient,public sessionClient:SessionClient) { }

  getVendorsById(vendorMaster: VendorMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/getVendorById', vendorMaster);
  }
  getDocumentPath(uploadStudentProfileFiles:any[]): Observable<any> {
    let formData:FormData = new FormData();
    for (let x = 0; x < uploadStudentProfileFiles.length; x++) {
        formData.append("file",uploadStudentProfileFiles[x] );
    };
    // formData.append('enrollCourseMasterJson', new Blob([JSON.stringify(documentMaster)],
    //   {
    //       type: "application/json"
    //   }));
   // return this.http.post<any>(environment.apiUrl + '/document/getDocumentPath', documentMaster);
    return this.http.post<any>(environment.apiUrl + '/document/getDocumentPath', formData);
  }
  saveVendorMenu(vendorMenu: VendorMenu): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/saveVendorMenu', vendorMenu);
  }
  getPredefinedListByType(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/predefined/getPredefinedListByType', predefinedMaster);
  }
  getVendorMenuById(vendorMenu: VendorMenu): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/getVendorMenuById', vendorMenu);
  }
  getEnquiryList(enquiryMaster: EnquiryMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/enquiry/getEnquiryList', enquiryMaster);
  }
  deleteEnquiryList(enquiryMaster: EnquiryMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/enquiry/deleteEnquiry', enquiryMaster);
  }
  getEnquiryListLength(enquiryMaster: EnquiryMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/enquiry/getEnquiryListLength', enquiryMaster);
  }
  saveEnquiry(enquiryMaster: EnquiryMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/enquiry/saveEnquiry', enquiryMaster);
  }
  getVendorList(vendorMaster:VendorMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/getVendorList', vendorMaster);
  }
  getVendorListByLength(vendorMaster:VendorMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/getVendorListLength', vendorMaster);
  }
 
  getVendorById(vendorMaster:VendorMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/getVendorById', vendorMaster);
  
  }
  getVendorMenuListByVendorId(vendorMenu:VendorMenu): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/getVendorMenuListByVendorId', vendorMenu);
  
  }
  getVendorDocumentPath(file:any): Observable<any> {
    let formData:FormData = new FormData();
    // for (let x = 0; x < file.length; x++) {
        formData.append("file",file);
    // };
    // formData.append('enrollCourseMasterJson', new Blob([JSON.stringify(documentMaster)],
    //   {
    //       type: "application/json"
    //   }));
   // return this.http.post<any>(environment.apiUrl + '/document/getDocumentPath', documentMaster);
    return this.http.post<any>(environment.apiUrl + '/document/getDocumentPath', formData);
  }
  saveVendorDocument(vendorDocument:VendorDocument): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/saveVendorDocument', vendorDocument);
  
  }
  saveEnquiryDocument(enquiryDocument:EnquiryDocument): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/enquiry/saveEnquiryDocument', enquiryDocument);
  
  }
  getVendorDocumentListByVendorId(vendorDocument:VendorDocument): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/getVendorDocumentListByVendorId', vendorDocument);
  
  }
  deleteVendorMenu(vendorMenu: VendorMenu): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/deleteVendorMenu', vendorMenu);
  
  }
  getOrderList(orderMaster:OrderMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/order/getOrderList', orderMaster);
  
  }
  getOrderListLength(orderMaster:OrderMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/order/getOrderListLength', orderMaster);
  
  }
  deleteOrder(orderMaster:OrderMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/order/deleteOrder', orderMaster);
  
  }
  setOrderSession(orderMaster:OrderMaster) {
    this.orderMaster = orderMaster;
    this.sessionClient.saveSession("orderSession", JSON.stringify(this.orderMaster));
  }
  getOrderSession() { 
      this.orderMaster = JSON.parse(localStorage.getItem("orderSession"));
   
    return  this.orderMaster;
  }
  getOrderById(orderMaster:OrderMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/order/getOrderById', orderMaster);
  
  }
  getClientList(clientMaster:ClientMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/client/getClientList', clientMaster);
  
  }
  getClientListLength(clientMaster:ClientMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/client/getClientListLength', clientMaster);
  
  }
  activeDeactiveCustomer(clientMaster:ClientMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/client/activeDeactiveClient', clientMaster);
  
  }
  activeDeactiveVendor(vendorMaster:VendorMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/vendorActiveDeactive', vendorMaster);
  
  }
  setClientSession(clientMaster:ClientMaster) {
    this.clientMaster = clientMaster;
    this.sessionClient.saveSession("clientSession", JSON.stringify(this.clientMaster));
  }
  getClientSession() { 
    this.clientMaster = JSON.parse(localStorage.getItem("clientSession"));
    return  this.clientMaster;
  }
  getClientById(clientMaster:ClientMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/client/getClientById', clientMaster);
  
  }
  getDocumentListByEnquiry(enquiryDocument:EnquiryDocument): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/enquiry/getDocumentListByEnquiry', enquiryDocument);
  
  }
  deleteEnquiryDocument(enquiryDocument:EnquiryDocument): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/enquiry/deleteEnquiryDocument', enquiryDocument);
  
  }
  updateEnquiryStage(EnquiryCheckListMaster:EnquiryCheckListMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/enquiry/updateEnquiryStage', EnquiryCheckListMaster);
  
  }
  deleteVendorDocument(vendorDocument:VendorDocument): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/deleteVendorDocument', vendorDocument);
  
  }
  getEnquiryById(enquiryMaster: EnquiryMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/enquiry/getEnquiryById', enquiryMaster);
  
  }
  downloadEnquiry(enquiryMaster:EnquiryMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/enquiry/downloadEnquiry',enquiryMaster, httpOptions_FILE);
  } 
  exportVendor(vendorMaster:VendorMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/vendor/downloadVendor',vendorMaster, httpOptions_FILE);
  }  
  updateEnquiryStatus(enquiryMaster:EnquiryMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/enquiry/updateEnquiryStatus',enquiryMaster);
  }  
  getEnquiryStatusLogByEnquiryId(enquiryStatusLog:EnquiryStatusLog): Observable<any> {
    return this.http.post(environment.apiUrl+'/enquiry/getEnquiryStatusLogByEnquiryId',enquiryStatusLog);
  }    

  deleteVendorMenuOption(vendorMenuOptions: VendorMenuOptions): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/deleteVendorMenuOption', vendorMenuOptions);
  }

  deleteVendorMenuDocument(vendorMenuDocument: VendorMenuDocument): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/deleteVendorMenuDocument', vendorMenuDocument);
  }
   
  deleteVendorMenuAddon(vendorMenuAddons: VendorMenuAddons): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/deleteVendorMenuAddon', vendorMenuAddons);
  }
  savePredefineMaster(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/predefined/savePredefined', predefinedMaster);
  }
  getDocumentRequestList(documentRequest: DocumentRequest): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/documentrequest/getDocumentRequestList', documentRequest);
  }
  getDocumentRequestListLength(documentRequest: DocumentRequest): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/documentrequest/getDocumentRequestListLength', documentRequest);
  }
  getMenuRequestList(menuRequest: MenuRequest): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/menurequest/getMenuRequestList', menuRequest);
  }
  getMenuRequestListLength(menuRequest: MenuRequest): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/menurequest/getMenuRequestListLength', menuRequest);
  }
  changeDocumentStatus(documentRequest: DocumentRequest): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/documentrequest/changeDocumentStatus', documentRequest);
  }
  changeMenuStatus(menuRequest: MenuRequest): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/menurequest/updateMenuRequestStatus', menuRequest);
  }
  saveMenuRequest(menuRequest: MenuRequest): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/menurequest/saveMenuRequest', menuRequest);
  }
  getWishLists(clientRequest: ClientRequest): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/client/getClientRequestList', clientRequest);
  }
  getWishListsLength(clientRequest: ClientRequest): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/client/getClientRequestLength', clientRequest);
  }
  getuserHistoryLists(userKeywordSearch: UserKeywordSearch): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/getKeywordSearchList', userKeywordSearch);
  }
  getuserHistoryListsLength(userKeywordSearch: UserKeywordSearch): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/vendor/getKeywordSearchListLength', userKeywordSearch);
  }
  getUserRuleList(userRule: UserRule): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/rule/getUserRuleList', userRule);
  }
  getUserRuleListLength(userRule: UserRule): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/rule/getUserRuleListLength', userRule);
  }
  getUserRuleCount(userRule: UserRule): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/rule/getUserRuleCount', userRule);
  }
  changeUserRuleStatus(userRule: UserRule): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/rule/changeUserRuleStatus', userRule);
  }
  getRuleConfigList(ruleConfig: RuleConfig): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/rule/getRuleConfigList', ruleConfig);
  }
  saveRuleConfig(ruleConfig: RuleConfig): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/rule/saveRuleConfig', ruleConfig);
  }
  saveCategoryConfig(categoryconfig: CategoryConfig): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/categoryconfig/saveCategoryConfig', categoryconfig);
  }
  saveCategorySubConfig(categorySubConfig: CategorySubConfig): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/categoryconfig/saveCategorySubConfig', categorySubConfig);
  }
  getCategoryConfigAdminList(categoryconfig: CategoryConfig): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/categoryconfig/getCategoryConfigAdminList', categoryconfig);
  }
  downloadClientRequest(clientRequest:ClientRequest): Observable<any> {
    return this.http.post(environment.apiUrl+'/client/downloadClientRequest',clientRequest, httpOptions_FILE);
  }
  deleteCategorySubConfig(categorySubConfig:CategorySubConfig): Observable<any> {
    return this.http.post(environment.apiUrl+'/categoryconfig/deleteCategorySubConfig',categorySubConfig);
  }
  deleteCategoryConfig(categoryconfig: CategoryConfig): Observable<any> {
    return this.http.post(environment.apiUrl+'/categoryconfig/deleteCategoryConfig',categoryconfig);
  }
  downloadMenuRequest(menuRequest:MenuRequest): Observable<any> {
    return this.http.post(environment.apiUrl+'/menurequest/downloadMenuRequest',menuRequest, httpOptions_FILE);
  }
  downloadDocumentRequest(documentRequest: DocumentRequest): Observable<any> {
    return this.http.post(environment.apiUrl+'/documentrequest/downloadDocumentRequest',documentRequest, httpOptions_FILE);
  }
  downloadUserKeywordSearch(userKeywordSearch: UserKeywordSearch): Observable<any> {
    return this.http.post(environment.apiUrl+'/vendor/downloadUserKeywordSearch',userKeywordSearch, httpOptions_FILE);
  }
  downloadUserRule(userRule: UserRule): Observable<any> {
    return this.http.post(environment.apiUrl+'/rule/downloadUserRule',userRule, httpOptions_FILE);
  }
  downloadRuleConfig(ruleConfig: RuleConfig): Observable<any> {
    return this.http.post(environment.apiUrl+'/rule/downloadRuleConfig',ruleConfig, httpOptions_FILE);
  }
  savePredefinedBanner(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/predefined/savePredefined',predefinedMaster);
  }
  getPredefinedListByTypeLength(predefinedMaster: PredefinedMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/predefined/getPredefinedListByTypeLength',predefinedMaster);
  }
  getCategoryConfigAdminListLength(categoryconfig: CategoryConfig): Observable<any> {
    return this.http.post(environment.apiUrl+'/categoryconfig/getCategoryConfigAdminListLength',categoryconfig);
  }
  getRewardStatsByType(userRule: UserRule): Observable<any> {
    return this.http.post(environment.apiUrl+'/rule/getRewardStatsByType',userRule);
  }
  getUserAddressList(userAddress: UserAddress): Observable<any> {
    return this.http.post(environment.apiUrl+'/user/getUserAddressList',userAddress);
  }
  getClientReviewList(clientReview: ClientReview): Observable<any> {
    return this.http.post(environment.apiUrl+'/client/getClientReviewList',clientReview);
  }
  getClientRequestPredefinedList(clientReview: ClientReview): Observable<any> {
    return this.http.post(environment.apiUrl+'/client/getClientRequestPredefinedList',clientReview);
  }
  updateClientReviewStatus(clientReview: ClientReview): Observable<any> {
    return this.http.post(environment.apiUrl+'/client/updateClientReviewStatus',clientReview);
  }
  getClientReviewListLength(clientReview: ClientReview): Observable<any> {
    return this.http.post(environment.apiUrl+'/client/getClientReviewListLength',clientReview);
  }
  getVendorPrimaryDetailList(vendorMaster: VendorMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/vendor/getVendorPrimaryDetailList',vendorMaster);
  }
  getCustomerPrimaryDetailList(clientMaster: ClientMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/client/getClientPrimayDetailList',clientMaster);
  }
  updateCategorySubConfig(categorySubConfig: CategorySubConfig): Observable<any> {
    return this.http.post(environment.apiUrl+'/categoryconfig/updateCategorySubConfig',categorySubConfig);
  }
  updateCategorySubConfigFeature(categorySubConfig: CategorySubConfig): Observable<any> {
    return this.http.post(environment.apiUrl+'/categoryconfig/updateCategorySubConfig',categorySubConfig);
  }
  dailyEarnings(vendorMaster: VendorMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/vendor/dailyEarnings',vendorMaster);
  }
  getReviewPostedLength(clientReview: ClientReview): Observable<any> {
    return this.http.post(environment.apiUrl+'/vendor/getReviewPostedLength',clientReview);
  }
  getVendorMenuListLengthByVendorId(vendorMenu: VendorMenu): Observable<any> {
    return this.http.post(environment.apiUrl+'/vendor/getVendorMenuListLengthByVendorId',vendorMenu);
  }
  setVendorSession(vendorMaster:VendorMaster) {
    this.vendorMaster = vendorMaster;
    this.sessionClient.saveSession("vendorSession", JSON.stringify(this.vendorMaster));
  }
  getVendorSession() { 
      this.vendorMaster = JSON.parse(localStorage.getItem("vendorSession"));
   
    return  this.vendorMaster;
  }
  downLoadOrder(orderMaster: OrderMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/order/downloadOrderMaster',orderMaster,httpOptions_FILE);
  }
  deletePredefined(predefineMaster: PredefinedMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/predefined/deletePredefined',predefineMaster);
  }
  getBannerList(banner: Banner): Observable<any> {
    return this.http.post(environment.apiUrl+'/banner/getBannerList',banner);
  }
  deleteBannerList(banner: Banner): Observable<any> {
    return this.http.post(environment.apiUrl+'/banner/deleteBanner',banner);
  }
  saveBanner(banner: Banner): Observable<any> {
    return this.http.post(environment.apiUrl+'/banner/saveBanner',banner);
  }
  updateFeaturedBanner(banner: Banner): Observable<any> {
    return this.http.post(environment.apiUrl+'/banner/updateFeaturedBanner',banner);
  }
  saveUserAccountDetails(userAccountDetails: UserAccountDetails): Observable<any> {
    return this.http.post(environment.apiUrl+'/useraccount/saveUserAccountDetails',userAccountDetails);
  }
  getUserAccountDetailsById(userAccountDetails: UserAccountDetails): Observable<any> {
    return this.http.post(environment.apiUrl+'/useraccount/getUserAccountDetailsById',userAccountDetails);
  }
  getNotificationListByUser(notification: NotificationMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/notification/getNotificationListByUser',notification);
  }
  getNotificationListByUserLength(notification: NotificationMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/notification/getNotificationListByUserLength',notification);
  }

  orderClearanceById(orderMaster: OrderMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/order/orderClearanceById',orderMaster);
  }

  updateOrderStatus(orderMaster: OrderMaster): Observable<any> {
    return this.http.post(environment.apiUrl+'/order/updateOrderStatus',orderMaster);
  }
}

