import { Role } from './role.model';
import { User } from './user';
import { AccessControlMaster } from 'projects/utility/src/app/module/system/model/accesscontrolmaster.model';
import { PredefinedMaster } from 'src/app/shared/predefinedsettings/model/predefinedmaster.model';

export class UserLogin {
   public id:number
    public username:string;
    public usernameData:string;
    public password:string;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public dob: Date;
    public gender: string;
    public description: string;
    public userLogoPath: string;
    public address:string;
	public locationStr:string;
    public url:string;
    public roleName:string;
    public isDelete: string;
    public isDeactive: string;
    public currentPassword:string;
    public newPassword:string;
    public confirmNewPassword:string;
    public role:Role;
    public roles:Role[];
    public accessControlMasterList:AccessControlMaster[];
    public pageNumber:number;
    public pageSize:number;
    public  lat:number;
    public  lng:number;
    public mapAddress:string;
    public searchType:string;
    public currentUser:UserLogin;
    public confirmPassword:string;
    public fullName:string;
    public city:PredefinedMaster;
    public houseNo:string;
    public streetName:string;
    public townName:string;
    public landmark:string;
    public state:string;
    public pincode:string;
    public cityName:string;
    public country:string;
    public fssaiNo:string;
    public documentSelectedPath:string;
    public actualPath:string;
    public fileName:string;
    public documentName:string;
    public getName() {
        return this.firstName + ' ' +this.middleName +' ' + this.lastName;
    }
}




