import {Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output, TemplateRef, ViewChild} from '@angular/core';
import {cardToggle, cardClose, cardIconToggle} from './card-animation';
import { FilterModuleMaster } from 'projects/utility/src/app/module/common/filter/model/filtermodulemaster.model';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [cardToggle, cardClose, cardIconToggle],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {
  @Input() headerContent: string;
  @Input() title: string;
  @Input() blockClass: string;
  @Input() cardClass: string;
  @Input() classHeader = false;
  @Input() cardOptionBlock = false;
  @Input() refreshIcon:boolean = false;
  @Input() expandIcon:boolean = false;
  @Input() deleteIcon:boolean = false;
  @Input() minimizeIcon:boolean = false;
  @Input() filterCheck:boolean = false;
  @Input() addCheck:boolean = false;
  @Input() genericFilterInput:any=null;
  @Input() advanceCols:any[]=[];
  @Input() quickCols:any[]=[];
  @Input() genericFilter:any=null;
  @Input() filterModuleMasterList:FilterModuleMaster[]=[];
  @Input() showFilterButton: boolean = false;
  @Input() showBackButton: boolean = false;
  @Input() showUploadIcon: boolean = false;
  @Output() filterPerformEvent: EventEmitter<any> = new EventEmitter();
  @Output() addRecordEvent: EventEmitter<any> = new EventEmitter();
  @Output() toggleViewEvent: EventEmitter<any> = new EventEmitter();
  @Output() refreshFilterEvent: EventEmitter<any> = new EventEmitter();
  @Output() uploadEvent: EventEmitter<any> = new EventEmitter();
  @Input() filterTemplate: TemplateRef<any>;
  @ViewChild('cardwrap') cardwrap;
  cardToggle = 'expanded';
  cardClose = 'open';
  fullCard: string;
  fullCardIcon: string;
  @Input() loadCard = false;
  isCardToggled = true;
  cardLoad: string;
  cardIconToggle: string;
  cardhiden:any;
  @Input() showFilter: boolean=false;
  @Input() showConfigFilter:boolean=false;

  
  

  constructor() {
    this.fullCardIcon = 'fa-expand';
    this.cardIconToggle = 'an-off';
  }

  toggleView(status:boolean){
    console.log("toggleView")
    this.toggleViewEvent.emit(status);

    // if(this.showBackButton){
    //   this.addCheck = true;
    //   this.showBackButton = false;
    // }

    
  }

  ngOnInit() {
    if (this.cardOptionBlock) {
      this.cardToggle = 'false';
    }
  }

  toggleCard(event) {
    this.cardToggle = this.cardToggle === 'collapsed' ? 'expanded' : 'collapsed';
    
    // this.cardhiden=this.cardwrap.nativeElement;
    // this.cardhiden.style.overflow = 'hidden';
    // console.log(this.cardhiden.style.overflow)
  }

  toggleCardOption() {
    this.isCardToggled = !this.isCardToggled;
    this.cardIconToggle = this.cardIconToggle === 'an-off' ? 'an-animate' : 'an-off';
  }

  closeCard(event) {
    this.cardClose = this.cardClose === 'closed' ? 'open' : 'closed';
  }

  fullScreen(event) {
    this.fullCard = this.fullCard === 'full-card' ? '' : 'full-card';
    this.fullCardIcon = this.fullCardIcon === 'fa-expand' ? 'fa-compress' : 'fa-expand';
  }

  cardRefresh() {
    this.loadCard = true;
    this.cardLoad = 'card-load';
    setTimeout( () => {
      this.cardLoad = '';
      this.loadCard = false;
    }, 3000);
  }

  showLoader(){
    this.loadCard = true;
    this.cardLoad = 'card-load';
  }

  hideLoader(){
    this.cardLoad = '';
    this.loadCard = false;
  }

  filterPerform(){
    this.filterPerformEvent.emit(this.genericFilter);
  }
  toggleFilter(){
    if(this.showFilter){
      this.showFilter = false;
    }else{
      this.showFilter = true;
    }

  }

  addRecord(){
    this.addRecordEvent.emit("add");
    this.addCheck = true;
    // this.showBackButton = true;
  }


  refreshFilter(){
    this.refreshFilterEvent.emit();
  }
  removerecord(){
    // this.addRecordEvent.emit("add");
    this.showBackButton = false;
    this.addCheck = true;
  }

  upload(){
    this.uploadEvent.emit();
  }

}
