import { Injectable } from '@angular/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';


const TOKEN_KEY = 'AuthTokenAdmin';

@Injectable()
export class SessionClient {
  cognitoUserSession = CognitoUserSession;

  constructor() { }

  signOut() {

    console.log('Sign out !!');
    //  window.sessionStorage.removeItem(TOKEN_KEY);
    //  sessionStorage.removeItem(TOKEN_KEY);

    localStorage.removeItem(TOKEN_KEY);
    // localStorage.removeItem(TOKEN_KEY);
    //sessionStorage.removeItem('accessControlMasterMap');
    //window.sessionStorage.removeItem('accessControlMasterMap');
    //sessionStorage.clear();
    // this.authService.accessControlMasterMap=null;
    // window.sessionStorage.clear();
    localStorage.clear();
  }

  public saveToken(token: string) {
    //window.sessionStorage.removeItem(TOKEN_KEY);
    // window.sessionStorage.setItem(TOKEN_KEY,  token);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    // return sessionStorage.getItem(TOKEN_KEY);
    return localStorage.getItem(TOKEN_KEY)
  }

  public saveSession(key: string, value: string) {
    // window.sessionStorage.removeItem(key);
    // window.sessionStorage.setItem(key,value);
    localStorage.removeItem(key);
    localStorage.setItem(key, value);
  }

  public getSession(key: string): string {
    return localStorage.getItem(key);
    //return sessionStorage.getItem(key);
  }

  public getAccessToken(): string {
    // return sessionStorage.getItem(TOKEN_KEY);
    if (JSON.parse(localStorage.getItem("cognitoSession")) !== null) {
      return JSON.parse(localStorage.getItem("cognitoSession")).accessToken.jwtToken;
    }else{
      return null
    }
  }

}
