import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BankDetailWrapper } from '../model/bankDetailWrapper.model';
import { BasicInfoModel } from '../model/basicInfo.model';
import { BusinessAddressWrapper } from '../model/bussinessAdressWrapper.model';
import { RequestWrapperLoginRequest } from '../model/requestWrapperLoginRequest.model';
import { RequestWrapperNewMerchantContactInfo } from '../model/requestWrapperNewMerchantContactInfo.model';
import { UploadImages } from '../model/uploadImages.model';
import { User } from '../model/user';
import { UserLogin } from '../model/userlogin.model';
import { VendorMaster } from '../model/VendorMaster.model';
import { customerVerifyQRWrapper } from '../model/customerVerifyQRWrapper.model';


@Injectable()
export class UserService {

    constructor(private http: HttpClient) { }

    getUserDetailsByUsername(userLogin:UserLogin): Observable<any> {
        return this.http.post<any>(environment.apiUrl+'/user/getUserDetailsByUsername',userLogin);
    }

    sendOtpToUser(userLogin:UserLogin): Observable<any> {
        return this.http.post<any>(environment.apiUrl+'/user/sendOtpToUser',userLogin);
    }

    forgetPassword(userLogin:UserLogin): Observable<any> {
        return this.http.post<any>(environment.apiUrl+'/user/forgetPassword',userLogin);
    }
    saveVendor(vendorMaster:VendorMaster): Observable<any> {
        return this.http.post<any>(environment.apiUrl+'/vendor/saveVendor',vendorMaster);
    }
    getUserassword(userLogin:UserLogin): Observable<any> {
        return this.http.post<any>(environment.apiUrl+'/user/getUserPassById',userLogin);
      }

    otpAttempt(requestWrapperLoginRequestDto: RequestWrapperLoginRequest):Observable<any>{
        return this.http.post<any>(environment.apiUrl + '/public/merchant/opt-attempt', requestWrapperLoginRequestDto);
    }
    otpAttemptQR(customerLoginDTO: any):Observable<any>{
        return this.http.post<any>(environment.apiUrl + '/public/customer/send-mobile-otp', customerLoginDTO);
    }
    otpVerifyQR(customerVerifyQR: customerVerifyQRWrapper):Observable<any>{
        return this.http.post<any>(environment.apiUrl + '/public/customer/verify-mobile-otp', customerVerifyQR);
    }

    customerOtpAttempt(requestWrapperLoginRequestDto: RequestWrapperLoginRequest):Observable<any>{
        return this.http.post<any>(environment.apiUrl + '/public/customer/opt-attempt', requestWrapperLoginRequestDto);
    }

    addContactInfo(requestWrapperNewMerchantContactInfo: RequestWrapperNewMerchantContactInfo):Observable<any>{
        return this.http.post<any>(environment.apiUrl + '/public/merchant/add-contact-info', requestWrapperNewMerchantContactInfo);
    }

    getContactInfoStaticData():Observable<any>{
        return this.http.get<any>(environment.apiUrl + '/public/merchant/contact-info-static-data');
    }

    getMerchantQRData(alias:string):Observable<any>{
        return this.http.get<any>(environment.apiUrl + '/public/merchant/' +alias);
    }

    getBasicInfo():Observable<any>{
        return this.http.get<any>(environment.apiUrl + '/v1/merchant/get-basic-info');
    }

    getBankInfo():Observable<any>{
        return this.http.get<any>(environment.apiUrl + '/v1/merchant/get-bank-list');
    }
    
    getLoginInfo():Observable<any>{
        return this.http.get<any>(environment.apiUrl + '/v1/merchant/login');
    }

    getCustomerLoginInfo():Observable<any>{
        return this.http.get<any>(environment.apiUrl + '/v1/customer/login');
    }

    uploadImages(uploadImagesDto : UploadImages):Observable<any>{
        return this.http.post<any>(environment.apiUrl + '/v1/merchant/upload-images',uploadImagesDto);
    }

    saveBasicInfo(basicInfoModel: BasicInfoModel):Observable<any>{
        return this.http.post<any>(environment.apiUrl + '/v1/merchant/save-basic-info',basicInfoModel);
    }

    saveBusinessAddress(businessAddressWrapper: BusinessAddressWrapper):Observable<any>{
        return this.http.post<any>(environment.apiUrl + '/v1/merchant/save-business_address',businessAddressWrapper);
    }

    registerBankAccount(bankDetailsWrapper: BankDetailWrapper):Observable<any>{
        return this.http.post<any>(environment.apiUrl + '/v1/merchant/register_bank_account',bankDetailsWrapper);
    }

    uploadOwnerImage(ownerImageList:any[]): Observable<any> {
        let formData:FormData = new FormData();
        for (let x = 0; x < ownerImageList.length; x++) {
            formData.append("ownerImage",ownerImageList[x] );
        };
        return this.http.post<any>(environment.apiUrl + '/v1/merchant/upload-images', formData);
      }

      uploadEstablishmentImage(ownerImageList:any[],establishmentImageList:any[]): Observable<any> {
        let formData:FormData = new FormData();
        
        for (let x = 0; x < establishmentImageList.length; x++) {
            formData.append("images",establishmentImageList[x] );
        };
        for (let x = 0; x < ownerImageList.length; x++) {
            formData.append("ownerImage",ownerImageList[x] );
        };
        return this.http.post<any>(environment.apiUrl + '/v1/merchant/upload-images', formData);
      }


}