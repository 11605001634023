import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ViewEncapsulation } from '@angular/compiler/src/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {
  @Output() stepPerformEvent: EventEmitter<any> = new EventEmitter();
  @Input() items: MenuItem[];
  @Input() activeIndex: number = 0;

  constructor() { }

  ngOnInit() {

    
  }

}
