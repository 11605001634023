import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmployeeMaster } from '../model/employeemaster.model';
import { User } from '../../login/model/user';
import { UserLogin } from '../../login/model/userlogin.model';

  
  @Injectable()
  export class EmployeeService {
  
  
    constructor(private http: HttpClient) { }
  
    saveEmployee(employeeMaster:EmployeeMaster): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/employee/saveEmployee',employeeMaster);
    }

    registerEmployee(employeeMaster:EmployeeMaster): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/employee/registerEmployee',employeeMaster);
    }
    
    getEmployeeList(employeeMaster:EmployeeMaster): Observable<any> {
        return this.http.post<any>(environment.apiUrl+'/employee/getEmployeeList',employeeMaster);
      }

    getEmployeeListLength(employeeMaster:EmployeeMaster): Observable<any> {
        return this.http.post<any>(environment.apiUrl+'/employee/getEmployeeListLength',employeeMaster);
    }
    public deleteEmployee(employeeMaster:EmployeeMaster): Observable<any> {
        return this.http.delete(environment.apiUrl+"/employee/deleteEmployee/"+employeeMaster.employeeId);
    }

    getEmployeeStaffList(employeeMaster:EmployeeMaster): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/lookup/getEmployeeStaffList',employeeMaster);
    }
      

    fetchEmployeeByUsername(user:UserLogin): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/employee/fetchEmployeeByUsername',user);
    }
    
  }