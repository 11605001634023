import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './auth.service';
import { FilterModuleMaster } from '../common/filter/model/filtermodulemaster.model';
import { ModuleMaster } from '../system/model/modulemaster.model';
import { FieldConfigurationService } from '../field/service/fieldconfiguration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PredefinedMaster } from 'src/app/shared/predefinedsettings/model/predefinedmaster.model';
import { PredefinedService } from 'src/app/shared/predefinedsettings/service/predefined.service';

@Injectable()
export class CommonFunctionService {

  filterModuleMasterListTrainer: FilterModuleMaster[]=[];
  quickColsTrainer:any[]=[];

  filterModuleMasterListCourse: FilterModuleMaster[]=[];
  quickColsCourse:any[]=[];

  filterModuleMasterListLecture: FilterModuleMaster[]=[];
  quickColsLecture:any[]=[];

  filterModuleMasterListContent: FilterModuleMaster[]=[];
  quickColsContent:any[]=[];

  filterModuleMasterListFranchise: FilterModuleMaster[]=[];
  quickColsFranchise:any[]=[];

  filterModuleMasterListFranchiseCourse: FilterModuleMaster[]=[];
  quickColsFranchiseCourse:any[]=[];

  filterModuleMasterListStudent: FilterModuleMaster[]=[];
  quickColsStudent:any[]=[];

  filterModuleMasterListEnrollCourse: FilterModuleMaster[]=[];
  quickColsEnrollCourse:any[]=[];
  
  courseCategoryPredefinedMasterList: PredefinedMaster[]=[];
  videoTypePredefinedMasterList: PredefinedMaster[]=[];

  constructor(private authService:AuthService,private spinnerService:NgxSpinnerService,
    private fieldConfigurationService:FieldConfigurationService,
    private predefinedService:PredefinedService) { }

  getFilterListByModuleAndType(moduleCode:string,type:string){

      let filterModuleMaster=new FilterModuleMaster();
      filterModuleMaster.moduleMaster=new ModuleMaster();
      filterModuleMaster.moduleMaster.moduleCode=moduleCode;
    
      if(type==="ADVANCE"){
        filterModuleMaster.filterType="ADVANCE";
      }
      if(type==="QUICK"){
        filterModuleMaster.filterType="QUICK";
      }
    
      this.fieldConfigurationService.getFilterListByModuleAndType(filterModuleMaster).subscribe( data => {

          this.setFilterModuleMasters(data,moduleCode,type);
          
      },error =>{
        if (error instanceof HttpErrorResponse) {
          console.log("getFilterListByModuleAndType error");
          if (error.status === 200) {
    
              let jsonString=error.error.text;
              jsonString=jsonString.substr(0, jsonString.indexOf('{"timestamp"'));
              this.setFilterModuleMasters(jsonString,moduleCode,type);
          }else{
            this.authService.showMessage('error','Something went wrong', '');
        }
    
        }
      });

  }

  setFilterModuleMasters(data:any,moduleCode:string,type:string){

    if(type==="ADVANCE"){
      this.setFilterInRespectiveModule(data,moduleCode,type);
    }
    if(type==="QUICK"){
      console.log("Quick filter called");
      for(let filterModuleMaster of data){

        let cols: any = {
          filterModuleId:filterModuleMaster.filterModuleId,
          fieldName: filterModuleMaster.fieldName,
          fieldType:filterModuleMaster.fieldType,
          width : filterModuleMaster.width, 
          lookupValues:filterModuleMaster.lookupValues,
          lookupSelect:filterModuleMaster.lookupSelect,
          singleSelect:filterModuleMaster.singleSelect,
          optionLabel:filterModuleMaster.lookupLabelValue,
          defaultLabel:filterModuleMaster.defaultLabel,
          filterModuleMaster:filterModuleMaster,
          filterLookupList:filterModuleMaster.filterLookupList,
          lookupLabelValue:filterModuleMaster.lookupLabelValue
        };
        this.setFilterInRespectiveModule(cols,moduleCode,type);
      }
    }
  }

  setFilterInRespectiveModule(data:any,moduleCode:string,type:string){

    if(type==="ADVANCE"){

      if(moduleCode === "TRAINER-MGNT"){
        this.filterModuleMasterListTrainer=data;
      } else if(moduleCode === "COURSE-MGNT"){
        this.filterModuleMasterListCourse=data;
      } else if(moduleCode === "LECTURE-MGNT"){
        this.filterModuleMasterListLecture=data;
      } else if(moduleCode === "CONTENT-MGNT"){
        this.filterModuleMasterListContent=data;
      } else if(moduleCode === "FRANCHISE-MGNT"){
        this.filterModuleMasterListFranchise=data;
      } else if(moduleCode === "FRANCHISE-COURSE-MGN"){
        this.filterModuleMasterListFranchiseCourse=data;
      } else if(moduleCode === "STUDENT-MGNT"){
        this.filterModuleMasterListStudent=data;
      } else if(moduleCode === "ENROLL-COURSE-MGNT"){
        this.filterModuleMasterListEnrollCourse=data;
      }

    }
    if(type==="QUICK"){

      if(moduleCode === "TRAINER-MGNT"){
        this.quickColsTrainer.push(data);
      } else if(moduleCode === "COURSE-MGNT"){
        this.quickColsCourse.push(data);
      } else if(moduleCode === "LECTURE-MGNT"){
        this.quickColsLecture.push(data);
      } else if(moduleCode === "CONTENT-MGNT"){
        this.quickColsContent=data;
      } else if(moduleCode === "FRANCHISE-MGNT"){
        this.quickColsFranchise=data;
      } else if(moduleCode === "FRANCHISE-COURSE-MGN"){
        this.quickColsFranchiseCourse=data;
      } else if(moduleCode === "STUDENT-MGNT"){
        this.quickColsStudent=data;
      } else if(moduleCode === "ENROLL-COURSE-MGNT"){
        this.quickColsEnrollCourse=data;
      }

    }

  }

  getPredefinedMasterList(entityType:string){

    let predefinedMaster:PredefinedMaster = new PredefinedMaster();
    predefinedMaster.entityType = entityType;
    this.predefinedService.getPredefinedListByType(predefinedMaster).subscribe( data => {

      if(entityType === "COURSE-CATEGORY"){
        this.courseCategoryPredefinedMasterList = data;
      } else if(entityType === "VIDEO-TYPE"){
        this.videoTypePredefinedMasterList = data;
      }

    },error =>{
      if (error instanceof HttpErrorResponse) {  
        if (error.status === 200) {
            let jsonString=error.error.text;
            jsonString=jsonString.substr(0, jsonString.indexOf('{"timestamp"'));

            if(entityType === "COURSE-CATEGORY"){
              this.courseCategoryPredefinedMasterList = JSON.parse(jsonString);
            } else if(entityType === "VIDEO-TYPE"){
              this.videoTypePredefinedMasterList = JSON.parse(jsonString);
            }
      
        }
      }
    });

  }

}