import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterModuleMaster } from './model/filtermodulemaster.model';
import { FieldConfigurationService } from '../../field/service/fieldconfiguration.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit,AfterViewInit {
  @Output() filterInitEvent: EventEmitter<any> = new EventEmitter();
  @Output() filterPerformEvent: EventEmitter<any> = new EventEmitter();
  @Input() genericFilterInput:any=null;
  @Input() genericColsInput:any[]=[];

  @Input() filterModuleMasterList:FilterModuleMaster[]=[];
  @Input() selectedFilterModuleMasterList:FilterModuleMaster[]=[];
  @Input() showConfigFilter:boolean=false;


  genericFilter:any=null;
  genericCols:any[]=[];
  quickCols:any[]=[];
  maxColsPerRow:number=4;

  constructor(private fieldConfigurationService:FieldConfigurationService) { }

  ngOnInit() {
   // this.filterInitEvent.emit(null);
 //   console.log("init end FilterComponent "+this.advanceFilter.name);
    console.log("filter init")
    for(let filterModuleMaster of this.filterModuleMasterList){
      console.log("filterModuleMaster value "+filterModuleMaster.defaultLabel);
    }

  }
  
  ngAfterViewInit() {
    console.log("ngAfterViewInit filter");
  }

  filterRecord(){
   // console.log("filter record "+this.advanceFilter.name);
    this.filterPerformEvent.emit(null);
  }

  onItemSelect (event:any) {
    this.filterPerformEvent.emit(null);
    console.log(event.value);
  }

  filterSelection(event:any){
    this.genericColsInput=[];
    for(let filterModuleMaster of event.value){

      filterModuleMaster.showDefaultFilter=true;
console.log(filterModuleMaster);

      this.genericColsInput.push( {
      fieldName: filterModuleMaster.fieldName,
      fieldType:filterModuleMaster.fieldType,
      width : filterModuleMaster.width,
     // lookupValues:this[filterModuleMaster.lookupValues],
     lookupValues:filterModuleMaster.filterLookupList,
      lookupSelect:filterModuleMaster.lookupSelect,
      singleSelect:filterModuleMaster.singleSelect,
      optionLabel:filterModuleMaster.lookupLabelValue,
      defaultLabel:filterModuleMaster.defaultLabel,
      filterModuleMaster:filterModuleMaster,
      filterLookupList:filterModuleMaster.filterLookupList,
      lookupLabelValue:filterModuleMaster.lookupLabelValue

    });

     }

  }

  filterLookupDataBySearchValue(filterValue:string,filterData:any){

    if(filterValue===null || filterValue===undefined  ||  filterValue=='' || filterValue.length <4){
      filterData.lookupValues=[];
      return;
    }

    console.log("filterLookupDataBySearchValue");
    filterData.filterModuleMaster.searchValue1=filterValue;
    this.fieldConfigurationService.filterLookupDataBySearchValue(filterData.filterModuleMaster).subscribe( data => {
      filterData.lookupValues=data;
    },error =>{
      if (error instanceof HttpErrorResponse) {
        console.log("filterLookupDataBySearchValue error");
        if (error.status === 200) {
           // console.log(error.error.text);
           //json circular break at server side always add
           //extra information of timestamp and status along with
           //main json so parsing occurs still at status 200
           let jsonString=error.error.text;
           jsonString=jsonString.substr(0, jsonString.indexOf('{"timestamp"'));
           filterData.lookupValues=JSON.parse(jsonString);
        }else{
         // this.authService.showMessage('error','Something Went Wrong','');
      }

      }
    });

  }

  setDefaultFilters(filterModuleList:FilterModuleMaster[]){
    console.log("filter setDefaultFilters");
    this.genericColsInput=[];
    this.selectedFilterModuleMasterList=[];
//selectedFilterModuleMasterList

    for(let filterModuleMaster of filterModuleList){

      if(filterModuleMaster.showDefaultFilter){
        console.log(filterModuleMaster);
        this.genericColsInput.push( {
          fieldName: filterModuleMaster.fieldName,
          fieldType:filterModuleMaster.fieldType,
          width : filterModuleMaster.width,
         // lookupValues:this[filterModuleMaster.lookupValues],
         lookupValues:filterModuleMaster.filterLookupList,
          lookupSelect:filterModuleMaster.lookupSelect,
          singleSelect:filterModuleMaster.singleSelect,
          optionLabel:filterModuleMaster.lookupLabelValue,
          defaultLabel:filterModuleMaster.defaultLabel,
          filterModuleMaster:filterModuleMaster,
          filterLookupList:filterModuleMaster.filterLookupList,
          lookupLabelValue:filterModuleMaster.lookupLabelValue
    
        });
        this.selectedFilterModuleMasterList.push(filterModuleMaster);
      }


    }
  }

}
