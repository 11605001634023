import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModuleMaster } from '../model/modulemaster.model';

  
  @Injectable()
  export class ModuleMasterService {
  
  
    constructor(private http: HttpClient) { }
  
    saveModule(moduleMaster:ModuleMaster): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/module/saveModule',moduleMaster);
    }

    getModuleList(moduleMaster:ModuleMaster): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/module/getModuleList',moduleMaster);
    }

    getModuleListLength(moduleMaster:ModuleMaster): Observable<any> {
      return this.http.post<any>(environment.apiUrl+'/module/getModuleListLength',moduleMaster);
    }
    
    deleteModule(moduleMaster:ModuleMaster): Observable<any> {
      return this.http.delete<any>(environment.apiUrl+'/module/deleteModule'+moduleMaster.moduleId);
    }

  }