import { PredefinedMaster } from 'src/app/shared/predefinedsettings/model/predefinedmaster.model';
import { ClientMaster } from './ClientMaster.model';
import { OrderMenu } from './OrderMenu.model';
import { VendorMaster } from './VendorMaster.model';
import { VendorMenu } from './VendorMenu.model';

export class OrderMaster {
    public orderId: number;
    public code: string;
    public vendorMaster: VendorMaster;
    public vendorId: number;
    public vendorMenu: VendorMenu;
    public vendorMenuId: number;
    public clientMaster: ClientMaster;
    public clientId: number;
    public totalPrice: number;
    public orderStatus: PredefinedMaster;
    public orderStatusId: number;
    public clearanceStatus: PredefinedMaster;
    public clearanceStatusId: number;
    public clearanceDate: Date;
    public settlementAmount: number;
    public amountToPay: number;
    public refundAmount: number;
    public disputeDate: Date;
    public adminDisputeComment: string;
    public customerStatusPredefinedMaster;
    public customerStatusId: number;
    public deliveryAddress: string;
    public deliveryDate: Date;
    public deliveryTime: Date;
    public customerRequest: string;
    public orderRating: string;
    public orderFeedback: string;
    public isDelete: string;
    public isDeactive: String;
    public orderMenuList: OrderMenu[];
    public recordSize: number;
    public pageSize: number;
    public pageNumber: number;
    public createdDate: Date;
    public modifiedDate: Date;
    public orderStatusCode: string;
    public  clientName:string;
    public  vendorName:string;
    public paymentMode:string;
    public paymentModeDescription:string;
    public deliveredByName:string;
    public taxes:string;
    public deliveryCharges:string;
    public  houseNo:string;
    public  addressLine1:string;
    public  mapAddress:string;
    public deliveryDateList: Date[];
    public sortByField:string;
    public paymentStatusCode: string;
    public checkDispute: string;
    public checkClearance: string;
    public paymentClearanceFlag: string;
    public readyToDeliver:string;
}